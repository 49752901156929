export const FETCH_AVAIL_MAPPING = 'FETCH_AVAIL_MAPPING';
export const FETCH_AVAIL_MAPPING_REQUEST = 'FETCH_AVAIL_MAPPING_REQUEST';
export const FETCH_AVAIL_MAPPING_SUCCESS = 'FETCH_AVAIL_MAPPING_SUCCESS';
export const FETCH_AVAIL_MAPPING_ERROR = 'FETCH_AVAIL_MAPPING_ERROR';
export const STORE_AVAIL_MAPPING = 'STORE_AVAIL_MAPPING';

export const FETCH_AVAIL_SELECT_VALUES_REQUEST = 'FETCH_AVAIL_SELECT_VALUES_REQUEST';
export const FETCH_AVAIL_SELECT_VALUES_SUCCESS = 'FETCH_AVAIL_SELECT_VALUES_SUCCESS';
export const FETCH_AVAIL_SELECT_VALUES_ERROR = 'FETCH_AVAIL_SELECT_VALUES_ERROR';
export const STORE_AVAIL_SELECT_LIST = 'STORE_AVAIL_SELECT_LIST';

export const FETCH_AVAIL_CONFIGURATION = 'FETCH_AVAIL_CONFIGURATION';
export const FETCH_AVAIL_CONFIGURATION_REQUEST = 'FETCH_AVAIL_CONFIGURATION_REQUEST';
export const FETCH_AVAIL_CONFIGURATION_SUCCESS = 'FETCH_AVAIL_CONFIGURATION_SUCCESS';
export const FETCH_AVAIL_CONFIGURATION_ERROR = 'FETCH_AVAIL_CONFIGURATION_ERROR';
export const STORE_AVAIL_CONFIGURATION_REPORTS = 'STORE_AVAIL_CONFIGURATION_REPORTS';

export const HANDLE_MATCHING_RIGHTS = 'HANDLE_MATCHING_RIGHTS';
