import React, {Suspense} from 'react';
import PropTypes from 'prop-types';
import {RestrictedRoute} from '@portal/portal-auth/permissions';
import {Route, Routes} from 'react-router-dom';
import Loading from './pages/static/Loading';

const Router = ({routes, titleMetadataFilter}) => {
    return <Routes>{routes.map(route => buildRoute(route))}</Routes>;
};

const buildRoute = route => {
    const {path, resource, key, element, children, ...rest} = route;
    return (
        <Route
            {...rest}
            key={path || key}
            path={path}
            element={
                <RestrictedRoute resource={resource}>
                    <Suspense fallback={<Loading />}>
                        {/*<Element />*/}
                        {route.element}
                    </Suspense>
                </RestrictedRoute>
            }
        >
            {children?.map(childRoute => buildRoute(childRoute))}
        </Route>
    );
};

Router.propTypes = {
    routes: PropTypes.array.isRequired,
    titleMetadataFilter: PropTypes.object,
};

Router.defaultProps = {
    titleMetadataFilter: {},
};

export default Router;
