import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, MultiSelect, Tooltip} from '@portal/portal-components';
import {cloneDeep} from 'lodash';
import {compose} from 'redux';
import withOptionalCheckbox from '../nexus-dynamic-form/hoc/withOptionalCheckbox';
import {formatOptions} from '../nexus-dynamic-form/utils';
import './NexusSelect.scss';

const DropdownWithOptional = compose(withOptionalCheckbox())(Dropdown);
const MultiselectWithOptional = compose(withOptionalCheckbox())(MultiSelect);

const NexusSelect = ({
    fieldProps,
    type,
    id,
    optionsConfig,
    selectValues,
    path,
    isRequired,
    isMultiselect,
    language,
    addedProps,
    optionsFilterParameter,
    showLocalized,
    isCreateMode,
    getValues,
    key,
    isModal,
}) => {
    const getPath = () => {
        return path.split('.').at(-1);
    };

    const fieldValues = fieldProps?.value;
    const [fetchedOptions, setFetchedOptions] = useState([]);
    const [ddlOptions, setDdlOptions] = useState([]);

    useEffect(() => {
        if (optionsConfig.options === undefined && !fetchedOptions.length) {
            if (Object.keys(selectValues).length) {
                let options = cloneDeep(selectValues[getPath()]);
                options = formatOptions(options, optionsConfig);
                addDeselectOption(options);
                setFetchedOptions(options.sort(selectCompare));
            }
        } else {
            const opts =
                optionsConfig.options !== undefined ? optionsConfig.options.sort(selectCompare) : filterOptions();
            setDdlOptions(opts);
        }
    }, [selectValues]);

    useEffect(() => {
        const opts = optionsConfig.options !== undefined ? optionsConfig.options.sort(selectCompare) : filterOptions();
        setDdlOptions(opts);
    }, [fetchedOptions]);

    // function to compare localized values in English
    const selectCompare = (a, b) => {
        // extract english text in brackets
        return a?.label?.split('(')[1] > b?.label?.split('(')[1]
            ? 1
            : b?.label?.split('(')[1] > a?.label?.split('(')[1]
            ? -1
            : 0;
    };

    const addDeselectOption = options => {
        if (type === 'select' && !isRequired) {
            const deselectOption = {label: 'Select...', value: null};
            options.unshift(deselectOption);
        }
    };

    const filterOptions = () => {
        if (optionsFilterParameter.length) {
            const options = cloneDeep(selectValues[getPath()]);
            let filteredOptions = [];
            optionsFilterParameter.forEach(({name, value}) => {
                const newValue = value || getValues(name);
                const [fieldName, propName] = name.split('.');
                const property = propName || fieldName;
                const filteredArray = options.filter(opt => opt[property] === newValue);
                filteredOptions = filteredOptions.concat(filteredArray);
            });
            return formatOptions(filteredOptions, optionsConfig);
        }
        return fetchedOptions;
    };

    const options = optionsConfig.options !== undefined ? optionsConfig.options.sort(selectCompare) : filterOptions();

    useEffect(() => {
        if (getPath() === 'metadataStatus') {
            const selectLabel = fieldValues?.label;
            if (selectLabel) {
                fieldValues.label = selectLabel[0].toUpperCase() + selectLabel.substr(1);
            }
        }

        if (getPath() === 'advisoriesCode' && fieldValues) {
            fieldValues.forEach(fieldValue => {
                const labelFromOptions = options?.find(option => option.value === fieldValue?.value)?.label;
                if (labelFromOptions && fieldValue?.label) fieldValue.label = labelFromOptions;
            });
        }
    }, [fieldValues, options]);

    return (
        <div className="nexus-select">
            {isMultiselect ? (
                ddlOptions?.length ? (
                    <MultiselectWithOptional
                        {...fieldProps}
                        elementId={id}
                        key={key}
                        options={ddlOptions}
                        placeholder="Select"
                        display="chip"
                        filterBy="label,value"
                        filter={ddlOptions.length >= 10}
                        appendTo={isModal ? document.body : 'self'}
                    />
                ) : (
                    <div>
                        <Tooltip
                            target=".disabled-multiselect"
                            content="No available options to select"
                            position="bottom"
                        />
                        <span className="disabled-multiselect">
                            <MultiselectWithOptional
                                {...fieldProps}
                                elementId={id}
                                options={ddlOptions}
                                filter={ddlOptions.length >= 10}
                                placeholder="Select"
                                appendTo={isModal ? document.body : 'self'}
                                disabled={true}
                            />
                        </span>
                    </div>
                )
            ) : (
                <DropdownWithOptional
                    {...fieldProps}
                    {...addedProps}
                    className="nexus-c-nexus-select-container"
                    elementId={id}
                    options={ddlOptions}
                    appendTo={isModal ? document.body : 'self'}
                    placeholder="Select"
                    optionValue="value"
                    filterBy="label,value"
                    filter={ddlOptions.length >= 10}
                />
            )}
        </div>
    );
};

NexusSelect.propTypes = {
    fieldProps: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    optionsConfig: PropTypes.object,
    selectValues: PropTypes.object,
    path: PropTypes.any,
    isRequired: PropTypes.bool,
    isMultiselect: PropTypes.bool,
    addedProps: PropTypes.object.isRequired,
    optionsFilterParameter: PropTypes.array,
    isCreateMode: PropTypes.bool,
    showLocalized: PropTypes.bool,
    language: PropTypes.string,
    id: PropTypes.string,
    nexusDynamicForm: PropTypes.any,
    getValues: PropTypes.func,
    key: PropTypes.string,
    isModal: PropTypes.bool,
};

NexusSelect.defaultProps = {
    optionsConfig: {},
    selectValues: {},
    path: null,
    isRequired: false,
    isMultiselect: false,
    optionsFilterParameter: [],
    isCreateMode: false,
    showLocalized: false,
    language: 'en',
    id: '',
    nexusDynamicForm: {},
    getValues: () => null,
    key: '',
    isModal: false,
};

export default NexusSelect;
