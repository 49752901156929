import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Dialog, DragDropList, PortalAutoComplete} from '@portal/portal-components';
import {cloneDeep, startCase, toLower} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {uid} from 'react-uid';
import {getConfigApiValues} from '../../settings/CommonConfigService';
import {addToast} from '../../toast/NexusToastNotificationActions';
import {configService} from '../../utils/services/ConfigService';
import CreateEditCharacterName from '../nexus-create-edit-character-name/CreateEditCharacterName';
import CreateEditConfig from '../nexus-create-edit-config/CreateEditConfig';
import {PROPAGATE_TITLE} from '../nexus-dynamic-form/constants';
import {checkIfEmetIsEditorial} from '../nexus-dynamic-form/utils';
import NexusPerson from '../nexus-person/NexusPerson';
import PropagateForm from '../nexus-person/elements/PropagateForm/PropagateForm';
import NexusPersonRO from '../nexus-person-ro/NexusPersonRO';
import {loadOptions} from './utils';
import {CAST, CAST_CONFIG, SEASON} from './constants';
import './NexusPersonsList.scss';

const propagateRemovePersonsSelector = state => state?.titleMetadata?.propagateRemovePersons || [];

export const PROPAGATE_REMOVE_PERSONS = 'PROPAGATE_REMOVE_PERSONS';
export const removeSeasonPerson = payload => ({
    type: PROPAGATE_REMOVE_PERSONS,
    payload,
});

const NexusPersonsList = ({
    personsList,
    uiConfig,
    getValues,
    setFieldValue,
    hasCharacter,
    isEdit,
    updateCastCrew,
    searchPerson,
    castCrewConfig,
    emetLanguage,
    setUpdate,
    isVerticalLayout,
    isEditable,
    castCrewPersons,
    forMetadata,
    ...props
}) => {
    const dispatch = useDispatch();
    const propagateRemovePersons = useSelector(propagateRemovePersonsSelector);
    const personTypes = useSelector(state => state?.titleMetadata?.personTypes || []);

    const [openPersonModal, setOpenPersonModal] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [displayValidationModal, setValidationModal] = useState(false);
    const [displayRemovePersonModal, setRemovePersonModal] = useState(false);
    const [displayCharacterNameModal, setCharacterNameModal] = useState(false);
    const [displayPropagateModal, setPropagateModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selectedPerson, setSelectedPerson] = useState({});
    const [currentRecord, setCurrentRecord] = useState({});
    const [personOptions, setPersonOptions] = useState([]);
    const [persons, setPersons] = useState(personsList || []);
    const [, setDragPersonId] = useState(undefined);

    const {title, name, contentType, castCrew = [], editorialMetadata = []} = getValues();

    const selectedPersonIdDrag = useRef(undefined);

    useEffect(() => {
        const updatedPersons = [...personsList];
        updatedPersons.forEach((person, index) => {
            // Avails crew doesn't come with id so displayName is used instead
            // eslint-disable-next-line no-unused-expressions
            !person.hasOwnProperty('id') ? (person.id = person.displayName) : person;
            person.order = index;
        });
        setPersons(updatedPersons);
    }, [personsList]);

    const isPersonValid = entry => {
        return (
            persons === null ||
            persons.findIndex(
                person =>
                    person.displayName.toString().toLowerCase() === entry.displayName.toString().toLowerCase() &&
                    person.personType.toString().toLowerCase() === entry.personType.toString().toLowerCase()
            ) < 0
        );
    };

    const validateAndAddPerson = personJSON => {
        const person = JSON.parse(personJSON.original);
        const isValid = isPersonValid(person);
        if (isValid) {
            addPerson(person);
            setSearchText('');
        } else {
            setValidationModal(true);
        }
    };

    const addPerson = person => {
        const updatedPersons = [...persons];

        if (person['personTypes'] && Array.isArray(person['personTypes'])) {
            const personWithType = {...person};
            delete personWithType['personTypes'];

            person['personTypes'].forEach(personType => {
                updatedPersons.push({...personWithType, personType});
            });
        } else {
            updatedPersons.push(person);
        }

        const isCast = uiConfig.type === CAST;
        updatedPersons.forEach((person, index) => {
            person.order = index;
        });
        setPersons(updatedPersons);
        updateCastCrew(updatedPersons, isCast);
    };

    const removePerson = person => {
        const updatedPersons = persons;
        updatedPersons.splice(persons.indexOf(person), 1);

        const isCast = uiConfig.type === CAST;
        updatedPersons.forEach((person, index) => {
            person.order = index;
        });
        setPersons(updatedPersons);
        updateCastCrew(updatedPersons, isCast);

        if (!isVerticalLayout && contentType === SEASON) {
            let isDuplicate = false;
            propagateRemovePersons.forEach(entry => {
                if (entry.id === person.id && entry.personType === person.personType) {
                    isDuplicate = true;
                }
            });

            const {id, personType, order} = person;
            const payload = isDuplicate
                ? propagateRemovePersons
                : [...propagateRemovePersons, {id, personType, order, propagateToEmet: true}];

            dispatch(removeSeasonPerson(payload));
        }

        const updateEditorialMetadata = editorialMetadata?.map(emet => {
            const updatedCastCrew = castCrewPersons.filter(entry => {
                return entry.id !== person.id || entry.personType !== person.personType;
            });

            const updatedEmet = {
                ...emet,
                castCrew: updatedCastCrew,
            };

            const formValues = getValues();

            if (checkIfEmetIsEditorial(emet, formValues)) {
                setFieldValue('editorial', {...formValues, castCrew: updatedCastCrew});
                if (isVerticalLayout) {
                    return updatedEmet;
                }
            }

            if (!isVerticalLayout) {
                return updatedEmet;
            }
            return emet;
        });

        const updatedCastCrew = castCrew
            ? castCrew?.filter(entry => {
                  return entry.id !== person.id || entry.personType !== person.personType;
              })
            : null;

        !isVerticalLayout && setFieldValue('castCrew', updatedCastCrew);
        editorialMetadata && setFieldValue('editorialMetadata', updateEditorialMetadata);
        setRemovePersonModal(false);
        setUpdate(prev => !prev);
    };

    const personHasCharName = person => {
        const currentPersonType = personTypes?.find(personType => personType.value === person.personType);
        return currentPersonType ? currentPersonType.canHaveCharacterName : false;
    };

    const handleAddCharacterName = name => {
        const formValues = getValues();
        const {castCrew = []} = formValues;

        // updates the specific column array - crew or cast
        const updatedPersons = persons.map(person => {
            return person.id === selectedPerson.id && personHasCharName(person)
                ? {...person, characterName: name}
                : person;
        });
        const isCast = uiConfig.type === CAST;

        updateCastCrew(updatedPersons, isCast);
        setPersons(updatedPersons);

        const updateEditorialMetadata = editorialMetadata?.map(emet => {
            const {castCrew: emetCastCrew = []} = emet;

            const updatedEmetCastCrew = emetCastCrew.map(person => {
                return person.id === selectedPerson.id && personHasCharName(person)
                    ? {...person, characterName: name}
                    : person;
            });
            return {
                ...emet,
                castCrew: updatedEmetCastCrew,
            };
        });

        // maps both cast & crew and sets it on the form
        const updatedAllCastCrew = castCrew?.map(person =>
            person.id === selectedPerson.id && personHasCharName(person) ? {...person, characterName: name} : person
        );

        !isVerticalLayout && setFieldValue('castCrew', updatedAllCastCrew);
        editorialMetadata.length && setFieldValue('editorialMetadata', updateEditorialMetadata);
        setCharacterNameModal(false);
    };

    const closePropagateModal = () => {
        setPropagateModal(false);
        setUpdate(prev => !prev);
    };

    const removeMessage = () => {
        if (isVerticalLayout) {
            return `Remove ${selectedPerson?.displayName} from this Emet`;
        } else if (contentType === SEASON) {
            return `Remove ${selectedPerson?.displayName} from this Season, it's Episodes and all related Emets?`;
        }

        return `Remove ${selectedPerson?.displayName} from ${title || name}?`;
    };

    const openRemoveModal = person => {
        setRemovePersonModal(true);
        setSelectedPerson(person);
    };

    const openCharacterNameModal = person => {
        if (person) {
            setSelectedPerson(person);
            setCharacterNameModal(true);
        }
    };

    const openPropagateModal = person => {
        setSelectedPerson(person);
        setPropagateModal(true);
    };

    const onEditPerson = async person => {
        const endpoint = castCrewConfig && castCrewConfig.urls && castCrewConfig.urls['CRUD'];
        const personData = await configService.get(endpoint, person.id);
        setCurrentRecord(personData);
        setOpenPersonModal(true);
    };

    const onListChange = items => {
        setPersons(items);
        const isCast = uiConfig.type === CAST;
        updateCastCrew(items, isCast);
    };

    const listItemTemplate = (person, index, dragRef, isDragging) => {
        const customKey = person.id ? uid(person.id, index) : `${person.displayName}-${index}`;
        return (
            <div
                className={`d-flex person-item-wrapper px-2 align-items-center justify-content-between ${
                    isDragging ? 'drag-background' : ''
                }`}
                style={{
                    border: isDragging ? '1px solid #5274b1' : '',
                }}
            >
                <i
                    className="po po-drag-handle cursor-pointer item-drag-handle"
                    ref={dragRef}
                    onMouseEnter={() => {
                        !selectedPersonIdDrag.current && setDragPersonId(person.id);
                        if (person.id !== selectedPersonIdDrag.current) {
                            selectedPersonIdDrag.current = person.id;
                        }
                    }}
                    onMouseLeave={() => {
                        selectedPersonIdDrag.current = undefined;
                        setDragPersonId(undefined);
                    }}
                />
                <NexusPerson
                    isEditable={isEditable}
                    key={customKey}
                    person={person}
                    personCanHaveCharacterName={personHasCharName(person)}
                    customKey={customKey}
                    index={index}
                    hasCharacter={hasCharacter}
                    onRemove={() => openRemoveModal(person)}
                    onPropagate={() => openPropagateModal(person)}
                    onEditPerson={() => onEditPerson(person)}
                    onEditCharacterName={() => openCharacterNameModal(person)}
                    emetLanguage={emetLanguage}
                    {...props}
                />
            </div>
        );
    };

    const renderPersons = () => {
        return <DragDropList items={persons} itemTemplate={listItemTemplate} onChange={onListChange} opacity={0.6} />;
    };

    const renderPersonsRO = () => {
        return persons.map((person, i) => {
            return <NexusPersonRO key={uid(person.id, i)} person={person} emetLanguage={emetLanguage} />;
        });
    };

    const editRecord = val => {
        const newVal = {...currentRecord, ...val};
        const endpoint = castCrewConfig && castCrewConfig.urls && castCrewConfig.urls['CRUD'];

        const successToast = {
            severity: 'success',
            detail: `Cast or Crew has been successfully ${newVal.id ? 'updated.' : 'added.'}`,
        };

        setSubmitLoading(true);

        if (newVal.id) {
            configService.update(endpoint, newVal.id, newVal).then(
                response => {
                    dispatch(addToast(successToast));
                    const updatedList = persons.map(person => {
                        if (person.id === newVal.id) {
                            return {
                                displayName: response.displayName,
                                order: person.order,
                                personType: person.personType,
                                id: response.id,
                                firstName: response.firstName,
                                lastName: response.lastName,
                            };
                        }
                        return person;
                    });
                    setPersons(updatedList);
                    setCurrentRecord({});
                    updateCastCrew(updatedList, uiConfig.type === CAST);
                    setOpenPersonModal(false);
                    setSubmitLoading(false);
                },
                () => {
                    setSubmitLoading(false);
                }
            );
        } else {
            configService.create(endpoint, newVal).then(
                person => {
                    dispatch(addToast(successToast));
                    setCurrentRecord({});
                    setOpenPersonModal(false);
                    addPerson(person);
                    setSearchText('');
                    setSubmitLoading(false);
                },
                () => {
                    setSubmitLoading(false);
                }
            );
        }
    };

    const closePersonModal = () => {
        setOpenPersonModal(false);
        setCurrentRecord({});
    };

    const dataApi = (url, param, value) => getConfigApiValues(url, 0, 1000, '', param, value);

    const renderPersonToRemoveFooter = () => {
        return (
            <>
                <Button
                    elementId="removeModalRemoveBtn"
                    label="Remove"
                    onClick={() => removePerson(selectedPerson)}
                    className="p-button-outlined p-button-secondary"
                />
                <Button
                    elementId="removeModalCancelBtn"
                    label="Cancel"
                    onClick={() => setRemovePersonModal(false)}
                    className="p-button-outlined"
                />
            </>
        );
    };

    const onCompleteMethod = value => {
        setSearchText(value);
        setLoading(true);
        loadOptions?.(uiConfig, value, searchPerson, emetLanguage, castCrewConfig?.permissions)
            .then(res => {
                setPersonOptions(res);
                setLoading(false);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const searchItemTemplate = opt => {
        return (
            <div className="d-flex align-items-center gap-3">
                <i className="po po-user" />
                <div className="person-details-wrapper">
                    <div className="person-name">
                        <span>{opt.name}</span>
                    </div>
                    <div className="person-type">
                        <span>{startCase(toLower(opt.byline))}</span>
                    </div>
                </div>
            </div>
        );
    };

    const getAutoCompleteId = () => {
        const {id = '', path = ''} = props;
        const subId = props.isTitlePage ? 'core' : 'create';
        return `personListAutocompleteWrapper${id}_${uiConfig.htmlFor}_${path}_${subId}`;
    };

    return (
        <>
            <div className="nexus-c-nexus-persons-list__heading">{uiConfig.title}</div>
            {isEdit ? (
                <>
                    {castCrewConfig && openPersonModal && (
                        <CreateEditConfig
                            visible={openPersonModal}
                            schema={castCrewConfig?.uiSchema}
                            label={castCrewConfig?.displayName}
                            displayName={castCrewConfig?.displayName}
                            values={cloneDeep(currentRecord)}
                            onSubmit={editRecord}
                            submitLoading={submitLoading}
                            onHide={closePersonModal}
                            dataApi={dataApi}
                            permissions={castCrewConfig?.permissions}
                            isEdit={false}
                        />
                    )}
                    <div className="nexus-c-nexus-persons-list__add position-relative" id={getAutoCompleteId()}>
                        <PortalAutoComplete
                            clearAutoComplete={true}
                            placeholder={uiConfig.newLabel}
                            placeholderIcon="po po-search"
                            elementId={`${getAutoCompleteId()}_awc`}
                            wrapperClass="autocompletePersonsList"
                            clearValueAfterChange={true}
                            value={searchText}
                            itemTemplate={searchItemTemplate}
                            loading={loading}
                            stickyFooterButton={{
                                icon: 'po po-user',
                                clearTextInputValue: true,
                                hideButton: !!personOptions.length,
                                actionLabel: `Create '${searchText}'`,
                                onClick: () => setOpenPersonModal(true),
                            }}
                            onChange={validateAndAddPerson}
                            completeMethod={onCompleteMethod}
                            options={personOptions}
                            triggerCompleteMethodOnClick={false}
                            appendTo={document.getElementById(getAutoCompleteId())}
                        />
                    </div>
                    {persons && renderPersons()}
                </>
            ) : (
                <>{persons && renderPersonsRO()}</>
            )}
            <Dialog
                elementId="nexusModal_validation"
                visible={displayValidationModal}
                footer={() => (
                    <Button
                        elementId="validateOkBtn"
                        label="OK"
                        onClick={() => setValidationModal(false)}
                        className="p-button-outlined"
                    />
                )}
                style={{width: '25vw'}}
                onHide={() => null}
                closeOnEscape={false}
                closable={false}
            >
                <div className="nexus-c-nexus-persons-list__error-modal-title">Person already exists!</div>
            </Dialog>
            <Dialog
                elementId="nexusModal_removePerson"
                header="Remove"
                visible={displayRemovePersonModal}
                footer={renderPersonToRemoveFooter()}
                style={{width: '30vw'}}
                onHide={() => null}
                closeOnEscape={false}
                closable={false}
            >
                <p>{removeMessage()}</p>
            </Dialog>

            <CreateEditCharacterName
                visible={selectedPerson && displayCharacterNameModal}
                setIsVisible={setCharacterNameModal}
                onSubmit={handleAddCharacterName}
                person={selectedPerson}
                forMetadata={forMetadata}
            />

            <Dialog
                elementId="nexusModal_removePerson"
                header={PROPAGATE_TITLE}
                visible={displayPropagateModal}
                style={{width: '30vw'}}
                onHide={() => null}
                closeOnEscape={false}
                closable={false}
            >
                <PropagateForm
                    person={selectedPerson}
                    getValues={getValues}
                    setFieldValue={setFieldValue}
                    onClose={closePropagateModal}
                />
            </Dialog>
        </>
    );
};

NexusPersonsList.propTypes = {
    onChange: PropTypes.func,
    personsList: PropTypes.array,
    castCrewPersons: PropTypes.array,
    uiConfig: PropTypes.object,
    hasCharacter: PropTypes.bool,
    isEdit: PropTypes.bool,
    updateCastCrew: PropTypes.func,
    setFieldValue: PropTypes.func,
    getValues: PropTypes.func,
    searchPerson: PropTypes.func,
    castCrewConfig: PropTypes.object,
    emetLanguage: PropTypes.string,
    setUpdate: PropTypes.func,
    isVerticalLayout: PropTypes.bool,
    isEditable: PropTypes.bool,
    id: PropTypes.string,
    allData: PropTypes.object,
    path: PropTypes.string,
    isTitlePage: PropTypes.bool,
};

NexusPersonsList.defaultProps = {
    onChange: null,
    personsList: [],
    castCrewPersons: [],
    uiConfig: CAST_CONFIG,
    hasCharacter: false,
    isEdit: false,
    updateCastCrew: () => null,
    setFieldValue: () => null,
    getValues: () => null,
    searchPerson: () => null,
    setUpdate: () => null,
    castCrewConfig: {},
    emetLanguage: 'en',
    isVerticalLayout: false,
    isEditable: true,
    id: '',
    allData: {},
    path: '',
    isTitlePage: false,
};

export default NexusPersonsList;
