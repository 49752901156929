import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Dialog, Dropdown, Form, Label} from '@portal/portal-components';
import {cloneDeep} from 'lodash';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import './MsvIds.scss';
import NexusTagsContainer from '../../../../../nexus-tags-container/NexusTagsContainer';
import {sortOptions} from '../../../../utils';

const MsvIds = ({selectValues, data, isEdit, onChange, generateMsvIds, label}) => {
    const form = useForm({mode: 'all', reValidateMode: 'onChange'});

    const [msvIds, setMsvIds] = useState([]);
    const [toggleModal, setToggleModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [licensorOptions, setLicensorOptions] = useState([]);
    const [licenseeOptions, setLicenseeOptions] = useState([]);
    const routeParams = useParams();

    useEffect(() => {
        setMsvIds(data);
    }, [data]);

    useEffect(() => {
        if (Object.keys(selectValues).length) {
            const formattedLicensorOptions = getAndFormatOptions('licensor');
            const formattedLicenseeOptions = getAndFormatOptions('licensee');
            setLicensorOptions(formattedLicensorOptions);
            setLicenseeOptions(formattedLicenseeOptions);
        }
    }, [selectValues]);

    const getAndFormatOptions = field => {
        const options = cloneDeep(selectValues[field]);
        const formattedOptions = options.map(opt => {
            return {
                label: opt.value,
                value: opt.value,
            };
        });
        return sortOptions(formattedOptions);
    };

    const saveIdsData = ids => {
        setMsvIds(ids);
        onChange(ids);
    };

    const openAddMsvIdModal = e => {
        e.preventDefault();
        setToggleModal(true);
    };

    const handleAddMsvId = () => {
        const formValues = form.getValues();
        const {id} = routeParams;
        if (form.formState.isValid) {
            if (id && typeof generateMsvIds === 'function') {
                setLoading(true);
                generateMsvIds(id, formValues.licensor, formValues.licensee, data).then(generatedIds => {
                    if (Array.isArray(generatedIds) && generatedIds.length > 0) {
                        const updatedMsvIds = [...msvIds, ...generatedIds];
                        setMsvIds(updatedMsvIds);
                        onChange([...updatedMsvIds]);
                    }
                    setLoading(false);
                    setToggleModal(false);
                });
            } else {
                setToggleModal(false);
            }
        }
    };

    const renderFooter = () => {
        return (
            <div className="row">
                <div className="col-12 text-end">
                    <Button
                        elementId="btnGenerateIds"
                        label="Generate ID"
                        onClick={handleAddMsvId}
                        loading={loading}
                        disabled={!form.formState.isValid}
                        className="p-button-outlined mx-4"
                    />
                    <Button
                        elementId="btnCancelGenerateIds"
                        label="Cancel"
                        className="p-button-outlined p-button-secondary"
                        onClick={e => {
                            e.preventDefault();
                            form.reset();
                            setToggleModal(false);
                        }}
                    />
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="row">
                <div className="col-2">
                    <Label label={label} shouldUpper={true} stacked={false} />
                </div>
                <div className="col-10">
                    <NexusTagsContainer data={msvIds} saveData={saveIdsData} isEdit={isEdit} />
                    {isEdit && (
                        <div className="nexus-c-msv-ids-add-button">
                            <Button
                                elementId="btnOpenMsvModal"
                                label="+ Add MSV Association ID"
                                onClick={openAddMsvIdModal}
                                className="p-button-outlined p-button-secondary"
                            />
                        </div>
                    )}
                </div>
            </div>

            <Dialog
                elementId="nexusModal_msvModal"
                header="Generate MSV Association ID"
                visible={toggleModal}
                style={{width: '35vw'}}
                onHide={() => null}
                closeOnEscape={false}
                closable={false}
                footer={renderFooter()}
                onSubmit={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <div className="nexus-c-modal">
                    <Form onSubmit={handleAddMsvId} form={form}>
                        <Dropdown
                            formControlOptions={{
                                formControlName: 'licensor',
                                rules: {
                                    required: true,
                                },
                            }}
                            labelProps={{label: 'Licensor', isRequired: true}}
                            elementId="ddlLicensor"
                            placeholder="Select"
                            className="nexus-c-modal__select-container"
                            options={licensorOptions}
                            columnClass="col-12"
                            filter={true}
                        />
                        <Dropdown
                            formControlOptions={{
                                formControlName: 'licensee',
                                rules: {
                                    required: true,
                                },
                            }}
                            labelProps={{label: 'Licensee', isRequired: true}}
                            elementId="ddlLicensee"
                            placeholder="Select"
                            className="nexus-c-modal__select-container-bottom"
                            options={licenseeOptions}
                            columnClass="col-12"
                            filter={true}
                        />
                    </Form>
                </div>
            </Dialog>
        </div>
    );
};

MsvIds.propTypes = {
    data: PropTypes.array,
    isEdit: PropTypes.bool,
    selectValues: PropTypes.object,
    onChange: PropTypes.func,
    generateMsvIds: PropTypes.func,
    label: PropTypes.string,
};

MsvIds.defaultProps = {
    data: [],
    isEdit: false,
    selectValues: {},
    onChange: () => null,
    generateMsvIds: undefined,
    label: '',
};

export default MsvIds;
