import React from 'react';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@portal/portal-icons/portalicons.css';
import {createKeycloakInstance} from '@portal/portal-auth';
import {LicenseManager} from 'ag-grid-enterprise';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import '@portal/portal-components/index';
import {HistoryRouter} from 'redux-first-history/rr6';
import './styles/scss/index.scss';
import packageJson from '../package.json';
import AppProviders from './AppProviders';
import Router from './Router';
import ErrorBoundary from './common/components/nexus-error-boundary/ErrorBoundary';
import Toast from './common/toast/NexusToastNotification';
import {getAuthConfig, loadConfig, setConfig} from './common/utils/config';
import {defaultConfiguration} from './config';
import NotFound from './pages/static/NotFound';
import {routesWithTracking} from './routes';
import rootSaga from './saga';
import configureStore, {configureHistory} from './store';
import {configurePersistor} from './store-persist-config';
import {initializeTracker} from './util/hoc/withTracker';
import './styles/legacy/WeAre138.scss'; // TODO: lovely file name - remove
import './styles/legacy/global.scss'; // TODO; refactor
import './styles/bootstrap.scss';
import './styles/prime-custom.scss';

const AG_GRID_LICENSE_KEY =
    'Using_this_AG_Grid_Enterprise_key_( AG-047553 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( VUBIQUITY MANAGEMENT LIMITED )_is_granted_a_( Multiple Applications )_Developer_License_for_( 4 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 10 November 2024 )____[v2]_MTczMTE5NjgwMDAwMA==3faafebabd2a0c30a5485e18773fc4f8';
LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);

const root = ReactDOM.createRoot(document.getElementById('app') || document.createElement('div'));

const setEnvConfiguration = async () => {
    const {version: portalVersion} = packageJson;
    setConfig({portalVersion});
    setConfig(defaultConfiguration);

    await loadConfig('/config.json', '/endpoints.json');
    return new Promise(resolve => {
        resolve();
    });
};

setEnvConfiguration()
    .then(() => renderApp())
    .catch(error => {
        // eslint-disable-next-line
        console.error(error);
        root.render(<p>Problem with configuration, application cannot be started</p>);
    });

// temporary export -> we should not export store
// eslint-disable-next-line no-underscore-dangle
export const store = configureStore(window.__PRELOADED_STATE__ || {});
export const history = configureHistory(store);

const persistor = configurePersistor(store);

// eslint-disable-next-line
delete window.__PRELOADED_STATE__;

const App = () => (
    <HistoryRouter history={history}>
        <Provider store={store}>
            <AppProviders persistor={persistor}>
                <ErrorBoundary>
                    <Toast />
                    <Router routes={routesWithTracking()} />
                </ErrorBoundary>
            </AppProviders>
        </Provider>
    </HistoryRouter>
);

function renderApp() {
    const kconfig = getAuthConfig();
    createKeycloakInstance(kconfig);
    initializeTracker();
    store.runSaga(rootSaga);

    root.render(kconfig.realm ? <App /> : <NotFound />);
}
