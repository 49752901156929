export const CREATE_RIGHT_MATCHING_COLUMN_DEFS = 'CREATE_RIGHT_MATCHING_COLUMN_DEFS';
export const STORE_RIGHT_MATCHING_COLUMN_DEFS = 'STORE_RIGHT_MATCHING_COLUMN_DEFS';

export const FETCH_AND_STORE_FOCUSED_RIGHT = 'FETCH_AND_STORE_FOCUSED_RIGHT';
export const FETCH_FOCUSED_RIGHT_REQUEST = 'FETCH_FOCUSED_RIGHT_REQUEST';
export const FETCH_FOCUSED_RIGHT_SUCCESS = 'FETCH_FOCUSED_RIGHT_SUCCESS';
export const FETCH_FOCUSED_RIGHT_ERROR = 'FETCH_FOCUSED_RIGHT_ERROR';
export const STORE_FOCUSED_RIGHT = 'STORE_FOCUSED_RIGHT';
export const STORE_PENDING_RIGHT = 'STORE_PENDING_RIGHT';

export const FETCH_MATCHED_RIGHT = 'FETCH_MATCHED_RIGHT';
export const FETCH_MATCHED_RIGHT_REQUEST = 'FETCH_MATCHED_RIGHT_REQUEST';
export const FETCH_MATCHED_RIGHT_ERROR = 'FETCH_MATCHED_RIGHT_ERROR';

export const FETCH_COMBINED_RIGHT = 'FETCH_COMBINED_RIGHT';
export const FETCH_COMBINED_RIGHT_REQUEST = 'FETCH_COMBINED_RIGHT_REQUEST';
export const FETCH_COMBINED_RIGHT_SUCCESS = 'FETCH_COMBINED_RIGHT_SUCCESS';
export const FETCH_COMBINED_RIGHT_ERROR = 'FETCH_COMBINED_RIGHT_ERROR';

export const SAVE_COMBINED_RIGHT = 'SAVE_COMBINED_RIGHT';
export const SAVE_COMBINED_RIGHT_REQUEST = 'SAVE_COMBINED_RIGHT_REQUEST';
export const SAVE_COMBINED_RIGHT_SUCCESS = 'SAVE_COMBINED_RIGHT_SUCCESS';
export const SAVE_COMBINED_RIGHT_ERROR = 'SAVE_COMBINED_RIGHT_ERROR';

export const FETCH_RIGHT_MATCH_DATA_UNTIL_FIND_ID = 'FETCH_RIGHT_MATCH_DATA_UNTIL_FIND_ID';
export const FETCH_RIGHT_MATCH_DATA_UNTIL_FIND_ID_FAILED = 'FETCH_RIGHT_MATCH_DATA_UNTIL_FIND_ID_FAILED';
export const STORE_RIGHT_MATCH_DATA_WITH_IDS = 'STORE_RIGHT_MATCH_DATA_WITH_IDS';
export const CLEAN_STORED_RIGHT_MATCH_DATA_WITH_IDS = 'CLEAN_STORED_RIGHT_MATCH_DATA_WITH_IDS';

export const STORE_MATCHED_RIGHTS = 'STORE_MATCHED_RIGHTS';
export const STORE_MATCHED_RIGHTS_SUCCESS = 'STORE_MATCHED_RIGHTS_SUCCESS';

export const VALIDATE_CONFLICTING_RIGHTS = 'VALIDATE_CONFLICTING_RIGHTS';
