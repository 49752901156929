import React from 'react';
import PropTypes from 'prop-types';
import {PermissionProvider} from '@portal/portal-auth/permissions';
import {get} from 'lodash';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';
import {ThemeProvider} from 'styled-components';
import AppLoadProvider from './appLoad/AppLoadProvider';
import AuthProvider from './auth/AuthProvider';
import NexusDateTimeProvider from './common/components/nexus-date-time-context/NexusDateTimeProvider';
import CustomIntlProvider from './common/components/nexus-layout/CustomIntlProvider';
import theme from './common/styled/theme';
import {getAuthConfig} from './common/utils/config';
import {registerFetchInterceptor} from './util/http/httpInterceptor';
import rolesResourceMap from './config/resourceRoleMap.json';

const AppProviders = ({children, persistor}) => {
    // const rolesResourceMap = require('./config/resourceRoleMap.json');

    const selectedTenant = useSelector(state => state?.auth?.selectedTenant || {});
    const roles = get(selectedTenant, 'roles', []);
    const navigate = useNavigate();

    // register interceptor
    registerFetchInterceptor(selectedTenant);

    return (
        <AuthProvider>
            <PermissionProvider
                roles={roles}
                resourceRolesMap={rolesResourceMap}
                unauthorizedAction={() => navigate(`/${getAuthConfig().realm}/401`)}
            >
                <CustomIntlProvider>
                    <NexusDateTimeProvider>
                        <PersistGate loading={null} persistor={persistor}>
                            <ThemeProvider theme={theme}>
                                <AppLoadProvider>{children}</AppLoadProvider>
                            </ThemeProvider>
                        </PersistGate>
                    </NexusDateTimeProvider>
                </CustomIntlProvider>
            </PermissionProvider>
        </AuthProvider>
    );
};

AppProviders.propTypes = {
    persistor: PropTypes.object.isRequired,
};

export default AppProviders;
