export const RELATIVE_TIME_LABEL = 'Relative';
export const SIMULCAST_TIME_LABEL = 'Simulcast (UTC)';
export const START_DATE_ERROR = 'Start date must be before End date';
export const END_DATE_ERROR = 'End date must be after Start date';
export const START_DATE_EMPTY_ERROR = 'Start date must not be empty';
export const END_DATE_EMPTY_ERROR = 'End date must not be empty';
export const TIME_PLACEHOLDER = 'HH:mm';
export const SIMULCAST_TIME_FORMAT = ' hh:mm:ss A [(UTC)]';
export const RELATIVE_TIME_FORMAT = ' hh:mm:ss A';
export const TIMESTAMP_TIME_FORMAT = ' hh:mm:ss.SSS A [(UTC)]';
export const ATLASKIT_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm';
export const TIMESTAMP_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';
export const SIMULCAST_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss[Z]';
export const RELATIVE_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';
export const RELATIVE_DATE_FORMAT_WITHOUT_TIME = 'YYYY-MM-DD';
export const DISPLAY_DATE_FORMAT = 'MM/DD/YYYY';
export const DISPLAY_DATE_TIME_FORMAT = 'MM/DD/YYYY HH:mm';
export const DISPLAY_DATE_TIME_FORMAT_PLACEHOLDER = 'MM/DD/YYYY hh:mm a';
export const FILL_DATE_TIME = 59999; // 59 seconds and 999 milliseconds
export const FILL_DATE = 86399999; // 23 hours 59 minutes 59 seconds and 999 milliseconds
