import {
    ADD_TOAST,
    DISMISS_ALL_TOASTS,
    REMOVE_TOAST
} from './NexusToastNotificationActionTypes';

export const addToast = payload => ({
    type: ADD_TOAST,
    payload,
});

export const removeToast = () => ({
    type: REMOVE_TOAST,
});

export const dismissAllToasts = () => ({
    type: DISMISS_ALL_TOASTS,
});
