import {keycloak} from '@portal/portal-auth';

/**
 * This method takes the resourceAccess from keycloak
 * and filters it to return only the clients of current user along with their roles
 *
 * @returns {{roles, id: string, label: string}[]}
 */
export const getTenantOptions = () => {
    const {resourceAccess} = keycloak || {};
    // get client roles from keycloak
    // filter out clients that are not tenants
    const filteredResourceAccess = {...(resourceAccess || {})};
    delete filteredResourceAccess['account'];
    delete filteredResourceAccess['dop-workQueues'];
    delete filteredResourceAccess['realm-management'];

    return Object.entries(filteredResourceAccess).map((client, index) => {
        return {
            id: client[0],
            roles: [...client[1].roles],
            label: client[0],
        };
    });
};

export const isUserMultiTenant = () => {
    return getTenantOptions().length > 1;
};
