import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Form} from '@portal/portal-components';
import {findIndex, get, isEmpty, keys, pickBy, uniqBy} from 'lodash';
import {useForm} from 'react-hook-form';
import {renderNexusField} from '../utils';
import {VIEWS} from '../constants';

const NexusArrayCreateModal = ({
    fields,
    handleModalSubmit,
    selectValues,
    data,
    setFieldValue,
    getValues,
    generateMsvIds,
    searchPerson,
    castCrewConfig,
    initialData,
    setDisplayEditModal,
    prefix,
    allData,
    selectValuesDisabledByPath, // this array of objects when is instantiated correctly can disable multiple list items from a dropdown(select)
}) => {
    // disable dropdown options
    disableSelectOptions(selectValuesDisabledByPath, selectValues, allData);
    const form = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {...(fields.castCrew && {castCrew: initialData.castCrew})},
    });
    const [updatedValues, setUpdatedValues] = useState(null);
    const [ddlOptions, setDdlOptions] = useState(selectValues);

    useEffect(() => {
        let subscription;

        if (form?.watch) {
            subscription = form?.watch?.((value, {name, type}) => {
                setDdlOptions({...ddlOptions});
            });
        }

        return () => subscription?.unsubscribe();
    }, [form?.watch]);

    useEffect(() => {
        setDdlOptions(selectValues);
    }, [selectValues]);

    const getVisibleFields = allFields => {
        const updateFields = {...allFields};
        Object.keys(allFields).forEach(key => {
            const hide = get(allFields[key], 'hideInCreate');
            if (hide && updateFields[key]) {
                delete updateFields[key];
            }
        });

        return updateFields;
    };

    const onSubmit = values => {
        const newTenantDataValues = [];
        if (!isEmpty(values?.editorialMetadata)) {
            for (const [key, value] of Object.entries(values?.editorialMetadata)) {
                if ((key === 'sasktelInventoryId' || key === 'sasktelLineupId') && value) {
                    newTenantDataValues.push({
                        name: key,
                        value,
                    });
                }
            }
            values.editorialMetadata.tenantData = {
                simpleProperties:
                    newTenantDataValues && newTenantDataValues?.length ? uniqBy(newTenantDataValues, 'name') : [],
            };
        }

        handleModalSubmit(form.getValues());
    };

    return (
        <Form onSubmit={onSubmit} form={form}>
            <div>
                {Object.keys(getVisibleFields(fields)).map(key => {
                    return (
                        <div key={`${data.id}_${key}`} className="nexus-c-nexus-array-with-tabs__field">
                            {renderNexusField(key, VIEWS.CREATE, path => form.getValues(path), generateMsvIds, {
                                field: fields[key],
                                selectValues: ddlOptions,
                                setFieldValue: (name, value) => form.setValue(name, value, {shouldDirty: true}),
                                searchPerson,
                                castCrewConfig,
                                initialData: {contentType: initialData.contentType},
                                setUpdatedValues,
                                updatedValues: {...updatedValues, contentType: initialData.contentType},
                                prefix,
                                allData,
                                path: fields[key].path,
                                isModal: true,
                            })}
                        </div>
                    );
                })}
            </div>
            <div className="row mt-4">
                <div className="col-12 text-end">
                    <Button elementId="submitBtn" label="Submit" type="submit" className="p-button-outlined" />
                    <Button
                        elementId="cancelBtn"
                        label="Cancel"
                        onClick={e => {
                            e.preventDefault();
                            form.reset();
                            setDisplayEditModal(false);
                        }}
                        className="mx-4 p-button-outlined p-button-secondary"
                    />
                </div>
            </div>
        </Form>
    );
};
/**
 * disableSelectOptions is a method which enhance dropdown list(select) with the ability to disable list items(which are already selected)
 * This method adds to specific attributes the property disabled: true or disabled: false
 * @param {path: helps as find the selected dropdown options from form, selectFieldName: is the name of the select} selectValuesDisabledByPath
 * @param {all dropdown options from form(array of objects)} selectValues
 * @param {all data(assigned values) from form} allData
 */
const disableSelectOptions = (selectValuesDisabledByPath, selectValues, allData) => {
    selectValuesDisabledByPath.forEach(disabledObject => {
        const {path, selectFieldName} = disabledObject;

        // indexes includes all the disabled list items
        const indexes = keys(pickBy(selectValues?.[selectFieldName], {disabled: true}));
        // First step we need to find all the disabled list items and enable them(this makes our logic more reliable)
        for (const index of indexes) {
            const disabledOption = selectValues?.[selectFieldName][index] && {
                ...selectValues?.ratingSystem[index],
                disabled: false,
            };
            disabledOption && selectValues?.ratingSystem.splice(index, 1, disabledOption);
        }

        // Second step is to find the dropdown(select) option values that have been selected already and add disabled attribute
        // allData includes the already selected list items from dropdown values
        allData?.[path]?.forEach(selectedOption => {
            // index is the location of the list item of dropdown(select) that we want to disable
            const index = findIndex(selectValues?.[selectFieldName], {value: selectedOption?.[selectFieldName] || ''});
            // disabledOption is an object: In general is a copy of the original object(li - list item of dropdown)
            // and we just add the disabled attribute
            const disabledOption = selectValues?.[selectFieldName][index] && {
                ...selectValues?.ratingSystem[index],
                disabled: true,
            };
            // we replace the old object with the new object that includes disabled: true
            disabledOption && selectValues?.ratingSystem.splice(index, 1, disabledOption);
        });
    });
};

NexusArrayCreateModal.propTypes = {
    fields: PropTypes.object,
    data: PropTypes.array,
    getValues: PropTypes.func,
    setFieldValue: PropTypes.func,
    selectValues: PropTypes.object,
    generateMsvIds: PropTypes.func,
    searchPerson: PropTypes.func,
    castCrewConfig: PropTypes.object,
    initialData: PropTypes.object,
    setDisplayEditModal: PropTypes.func,
    handleModalSubmit: PropTypes.func,
    prefix: PropTypes.string,
    allData: PropTypes.object,
    selectValuesDisabledByPath: PropTypes.array,
};

NexusArrayCreateModal.defaultProps = {
    fields: {},
    data: [],
    getValues: undefined,
    setFieldValue: undefined,
    selectValues: {},
    generateMsvIds: undefined,
    searchPerson: undefined,
    castCrewConfig: {},
    initialData: {},
    setDisplayEditModal: () => null,
    handleModalSubmit: undefined,
    prefix: undefined,
    allData: {},
    selectValuesDisabledByPath: [{path: '', selectFieldName: ''}],
};

export default NexusArrayCreateModal;
