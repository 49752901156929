export const SET_SELECTED_RIGHTS = 'SET_SELECTED_RIGHTS';
export const SET_PREPLAN_RIGHTS = 'SET_PREPLAN_RIGHTS';
export const ADD_RIGHTS_FILTER = 'ADD_RIGHTS_FILTER';
export const ADD_RIGHTS_FILTER_SUCCESS = 'ADD_RIGHTS_FILTER_SUCCESS';
export const ADD_RIGHTS_FILTER_ERROR = 'ADD_RIGHTS_FILTER_ERROR';
export const REMOVE_RIGHTS_FILTER = 'REMOVE_RIGHTS_FILTER';
export const SET_RIGHTS_FILTER = 'SET_RIGHTS_FILTER';
export const GET_LINKED_TO_ORIGINAL_RIGHTS = 'GET_LINKED_TO_ORIGINAL_RIGHTS';
export const SET_LINKED_TO_ORIGINAL_RIGHTS = 'SET_LINKED_TO_ORIGINAL_RIGHTS';
export const BULK_DELETE_SELECTED_RIGHTS = 'BULK_DELETE_SELECTED_RIGHTS';
export const BULK_DELETE_SELECTED_RIGHTS_ERROR = 'BULK_DELETE_SELECTED_RIGHTS_ERROR';
export const GET_LINKED_TO_ORIGINAL_RIGHTS_ERROR = 'GET_LINKED_TO_ORIGINAL_RIGHTS_ERROR';
export const CLEAR_LINKED_TO_ORIGINAL_RIGHTS = 'CLEAR_LINKED_TO_ORIGINAL_RIGHTS';
export const GET_RIGHT = 'GET_RIGHT';
export const GET_RIGHT_REQUEST = 'GET_RIGHT_REQUEST';
export const GET_RIGHT_SUCCESS = 'GET_RIGHT_SUCCESS';
export const GET_RIGHT_ERROR = 'GET_RIGHT_ERROR';
export const UPDATE_RIGHT = 'UPDATE_RIGHT';
export const UPDATE_RIGHT_SUCCESS = 'UPDATE_RIGHT_SUCCESS';
export const UPDATE_RIGHT_ERROR = 'UPDATE_RIGHT_ERROR';
export const SAVING = 'SAVING';
export const EDITING = 'EDIT_RIGHT';
export const SET_AVAILS_USER_DEFINED_GRID = 'SET_AVAILS_USER_DEFINED_GRID';
export const CLEAR_RIGHT = 'CLEAR_RIGHT';
export const STORE_FROM_SELECTED_TABLE = 'STORE_FROM_SELECTED_TABLE';
export const SET_CURRENT_USER_VIEW_AVAILS = 'SET_CURRENT_USER_VIEW_AVAILS';
export const SET_AVAILS_GRID_STATE = 'SET_AVAILS_GRID_STATE';
export const SET_PREPLAN_COLUMN_DEF = 'SET_PREPLAN_COLUMN_DEF';
export const SET_SELECTED_RIGHTS_COL_DEF = 'SET_SELECTED_RIGHTS_COL_DEF';
