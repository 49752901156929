export const CAST_CONFIG = {
    type: 'CAST',
    title: 'Cast',
    newLabel: 'Add New Cast...',
    listLabel: 'Cast List',
    htmlFor: 'new-person-cast',
};
export const CREW_CONFIG = {
    type: 'CREW',
    title: 'Crew',
    newLabel: 'Add New Crew...',
    listLabel: 'Crew List',
    htmlFor: 'new-person-crew',
};

export const ACTOR = 'Actor';
export const DIRECTOR = 'Director';
export const WRITER = 'Writer';
export const PRODUCER = 'Producer';
export const ANIMATED_CHARACTER = 'Animated Character';
export const AWARD = 'Award';
export const RECORDING_ARTIST = 'Recording Artist';
export const VOICE_TALENT = 'Voice Talent';
export const FEATURE_ARTIST = 'Feature Artist';
export const DISPLAY_ARTIST = 'Display Artist';

export const DUBBING_TALENT = 'Dubbing Talent';
export const HOST = 'Host';
export const COACH = 'Coach';
export const JUDGE = 'Judge';

export const COMPOSER = 'Composer';
export const CREATOR = 'Creator';
export const DANCE_DIRECTOR = 'Dance Director';
export const EXECUTIVE_IN_CHARGE = 'Executive in Charge';
export const EXTERNAL_PRODUCER = 'External Producer';
export const FREMANTLE_PRODUCER = 'Fremantle Producer';
export const MUSIC_SCORING = 'Music Scoring';
export const OWNER = 'Owner';

export const CREW_LIST = [
    DIRECTOR,
    WRITER,
    PRODUCER,
    COMPOSER,
    CREATOR,
    DANCE_DIRECTOR,
    EXECUTIVE_IN_CHARGE,
    EXTERNAL_PRODUCER,
    FREMANTLE_PRODUCER,
    MUSIC_SCORING,
    OWNER,
];

export const CAST = 'CAST';
export const PERSONS_PER_REQUEST = 1000;
export const MIN_CHARS_FOR_SEARCHING = 2;

export const ADD_CHARACTER_NAME = 'Add New Character Name';
export const EDIT_CHARACTER_NAME = 'Edit Character Name';
export const EMPTY_CHARACTER_ERROR = 'CHARACTER NAME CANNOT BE EMPTY!';
export const LONG_CHARACTER_ERROR = 'CHARACTER NAME MUST BE LESS THAN 100 CHARACTERS LONG!';
export const LOCALIZED_NOT_DEFINED = 'Localized name not defined';
export const NEEDS_TRANSLATION = '(Needs translation)';

export const SEASON = 'SEASON';
