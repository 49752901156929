import React from 'react';
import {Component} from 'react';
import {render, unmountComponentAtNode} from 'react-dom';
import {Button, Dialog} from '@portal/portal-components';
import PropTypes from 'prop-types';

class ErrorModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: true,
        };

        this.toggle = this.toggle.bind(this);
        this.accept = this.accept.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }
    refresh() {
        window.location.reload();
    }

    accept() {
        return this.props.accept();
    }

    render() {
        return (
            <Dialog
                elementId="DialogErrorModal"
                onHide={() => {
                    this.setState({modal: !this.state.modal});
                }}
                visible={this.state.modal}
                className={this.props.className}
                header={this.props.message}
                headerStyle={{backgroundColor: '#dc3545'}}
                footer={
                    !this.props.closable && !this.props.status === 403 ? (
                        <Button
                            elementId="btnErrorModalRefreshAction"
                            label="Refresh"
                            className="p-button-outlined p-button-secondary"
                            onClick={this.refresh}
                        />
                    ) : (
                        <Button
                            label={this.props.buttonLabel}
                            className="p-button-outlined p-button-secondary"
                            onClick={this.accept}
                            elementId="btnErrorModalAcceptAction"
                        />
                    )
                }
            >
                {this.props.description}
            </Dialog>
        );
    }
}

export const errorModal = {
    open: (message, onApprove, options) => {
        if (options == null) {
            options = {};
        }
        const props = {
            ...options,
            message: message,
            accept: () => {
                cleanup();
                onApprove();
            },
        };
        const wrapper = document.body.appendChild(document.createElement('div'));
        render(<ErrorModal {...props} />, wrapper);
        const cleanup = function () {
            unmountComponentAtNode(wrapper);
            return setTimeout(function () {
                return wrapper.remove();
            });
        };
    },
};

ErrorModal.propTypes = {
    description: PropTypes.string,
    className: PropTypes.string,
    message: PropTypes.string,
    buttonLabel: PropTypes.string,
    accept: PropTypes.func,
    closable: PropTypes.bool,
};

ErrorModal.defaultProps = {
    ...Component.defaultProps,
    buttonLabel: 'Ok',
};
export default ErrorModal;
