const availsMigration = {
    // example
    0: state => {
        return {
            ...state,
            rights: {
                ...state.rights,
                list: {},
                total: 0,
            },
        };
    },
};

export default availsMigration;
