import {INCORRECT_YEAR, MAX_YEAR_LENGTH} from '../constants';

export function isYear(value) {
    const yearPattern = /^[1-9]\d\d\d$/;
    if (value && value.length > 4) {
        return MAX_YEAR_LENGTH;
    } else if (value && !yearPattern.test(value)) {
        return INCORRECT_YEAR;
    }
    return undefined;
}
