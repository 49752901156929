export const MOVIDA = {label: 'Movida', value: 'movida'};
export const MOVIDA_INTL = {label: "Movida Int'l", value: 'movida-uk'};
export const VZ = {label: 'VZ', value: 'vz'};
export const VU = 'VU';
export const MGM = 'MGM';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const SEASON = 'SEASON';
export const EPISODE = 'EPISODE';

export const TABLE_OPTIONS = [{label: 'All', value: 'all'}];
