import {
    COACH,
    COMPOSER,
    CREATOR,
    DANCE_DIRECTOR,
    DUBBING_TALENT,
    EXECUTIVE_IN_CHARGE,
    EXTERNAL_PRODUCER,
    FREMANTLE_PRODUCER,
    HOST,
    JUDGE,
    MUSIC_SCORING,
    OWNER,
} from '../../components/nexus-persons-list/constants';
import {
    ACTOR,
    ANIMATED_CHARACTER,
    CAST,
    DIRECTOR,
    DISPLAY_ARTIST,
    FEATURE_ARTIST,
    PRODUCER,
    RECORDING_ARTIST,
    VOICE_TALENT,
    WRITER,
} from '../castCrewUtils';
import {getApiURI} from '../config';
import {nexusFetch} from '../http-client';

const createMultiCastPersonTypePath = () => {
    const value =
        `${ACTOR},${ANIMATED_CHARACTER},${RECORDING_ARTIST},${VOICE_TALENT},${FEATURE_ARTIST},${DISPLAY_ARTIST},${DUBBING_TALENT},${HOST},${COACH},${JUDGE}&`.toLowerCase();
    return `personTypes=${value}`;
};

const createCastPersonTypePath = () => {
    return `personTypes=${ACTOR.toLowerCase()}&`;
};

const createCrewPersonTypePath = () => {
    const value =
        `${DIRECTOR},${WRITER},${PRODUCER},${COMPOSER},${CREATOR},${DANCE_DIRECTOR},${EXECUTIVE_IN_CHARGE},${EXTERNAL_PRODUCER},${FREMANTLE_PRODUCER},${MUSIC_SCORING},${OWNER}&`.toLowerCase();
    return `personTypes=${value}`;
};

const isTextEnglish = testString => {
    const englishChars = /^[A-Za-z0-9 ]*$/;
    return englishChars.test(testString);
};

export const searchPerson = (inputValue, size, castOrCrew, isMultiCastType = false) => {
    let displayNamePath = '?';
    if (inputValue) {
        if (isTextEnglish(inputValue)) {
            displayNamePath += `displayName=${inputValue}&`;
        } else {
            displayNamePath += `localization.displayName=${inputValue}&`;
        }
    }
    const sortPath = ';displayName=ASC';
    let personTypePath = null;

    if (isMultiCastType) {
        personTypePath = createMultiCastPersonTypePath();
    } else {
        personTypePath = castOrCrew === CAST ? createCastPersonTypePath() : createCrewPersonTypePath();
    }

    const uri = `/persons${sortPath}${displayNamePath}${personTypePath}page=0&size=${size}`;
    const url = getApiURI('configuration', uri);

    return nexusFetch(url, {isWithErrorHandling: false});
};

export const searchPersonById = personId => {
    const uri = `/persons/${personId}`;
    const url = getApiURI('configuration', uri);
    return nexusFetch(url, {isWithErrorHandling: false});
};
