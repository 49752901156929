import React from 'react';
import PropTypes from 'prop-types';
import {Button} from '@portal/portal-components';
import NexusDynamicForm from '../../../../NexusDynamicForm';

const AddRowDataItem = ({schema, selectValues, onAddRowDataItem, closeModal}) => {
    const formFooter = () => (
        <div className="row mt-4">
            <div className="col-12 text-end">
                <Button
                    elementId="btnCancelRowDataItem"
                    label="Cancel"
                    className="p-button-outlined p-button-secondary mx-2"
                    onClick={() => closeModal()}
                    type="button"
                />
                <Button
                    label="Submit"
                    elementId="btnSubmitRowDataItem"
                    className="p-button-outlined mx-2"
                    type="submit"
                />
            </div>
        </div>
    );

    return (
        <NexusDynamicForm
            isFullScreen={true}
            schema={schema}
            selectValues={selectValues}
            hasButtons={false}
            canEdit={true}
            formFooter={formFooter()}
            onSubmit={onAddRowDataItem}
        />
    );
};

AddRowDataItem.propTypes = {
    onAddRowDataItem: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    schema: PropTypes.object.isRequired,
    selectValues: PropTypes.object,
};

AddRowDataItem.defaultProps = {
    selectValues: {},
};

export default AddRowDataItem;
