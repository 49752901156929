import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Dialog, InputText} from '@portal/portal-components';

const CreateEditCharacterName = ({visible, setIsVisible, onSubmit, person, forMetadata}) => {
    const [textInput, setTextInput] = useState(person?.characterName || '');
    useEffect(()=> {
        if(person) {
            setTextInput(person?.characterName)
        }
    }, [person])

    const onHideDialog = () => {
        setIsVisible(false);
        setTextInput(person?.characterName || '');
    };

    const footer = (
        <div className="row">
            <div className="col-sm-12 text-end">
                <Button
                    elementId="btnCancelEditChar"
                    className="p-button-outlined p-button-secondary"
                    label="Cancel"
                    onClick={onHideDialog}
                />
                <Button
                    elementId="btnSaveEditChar"
                    className="p-button-outlined"
                    label="Save"
                    onClick={() => {
                        onSubmit(textInput);
                    }}
                />
            </div>
        </div>
    );

    return (
        <Dialog
            elementId="nexusModal_editCharacterName"
            header="Edit"
            visible={visible}
            footer={footer}
            style={{width: '30vw'}}
            onHide={() => null}
            closeOnEscape={true}
            closable={false}
        >
            <p>Below details are specific to the current Title.</p>
            <div className="row mt-4 mb-5">
                <div className="col-12 mb-4">
                    <InputText
                        elementId="inpIngestFilterLicensor"
                        labelProps={{label: forMetadata ? 'Localized Character Name' : 'Character Name', stacked: true, isRequired: false}}
                        type="text"
                        value={textInput}
                        placeholder="Enter Character Name..."
                        onChange={e => setTextInput(e.target.value)}
                    />
                </div>
            </div>
        </Dialog>
    );
};

CreateEditCharacterName.propTypes = {
    visible: PropTypes.bool,
    setIsVisible: PropTypes.func,
    onSubmit: PropTypes.func,
    person: PropTypes.object
};

CreateEditCharacterName.defaultProps = {
    visible: false,
    setIsVisible: () => null,
    onSubmit: () => null,
    person: {}
};

export default CreateEditCharacterName;
