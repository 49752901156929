import {ADD_TOAST, DISMISS_ALL_TOASTS, REMOVE_TOAST} from './NexusToastNotificationActionTypes';

const initialState = {
    toast: [],
};

const toastReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case ADD_TOAST:
            return {
                ...state,
                toast: [...state.toast, payload],
            };
        case REMOVE_TOAST: {
            return {
                ...state,
                toast: undefined,
            };
        }
        case DISMISS_ALL_TOASTS:
            return {
                ...state,
                toast: undefined,
            };
        default:
            return state;
    }
};

export default toastReducer;
