import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useAuth} from '@portal/portal-auth';
import {Restricted} from '@portal/portal-auth/permissions';
import {Form} from '@portal/portal-components';
import classnames from 'classnames';
import {isEmpty, mergeWith, set} from 'lodash';
import moment from 'moment';
import {useForm} from 'react-hook-form';
import ButtonsBuilder from './components/ButtonsBuilder/ButtonsBuilder';
import {buildSection, getAllFields, getProperValue, getProperValues} from './utils';
import {VIEWS} from './constants';
import './NexusDynamicForm.scss';

const NexusDynamicForm = ({
    schema,
    initialData,
    onSubmit,
    canEdit,
    selectValues,
    isSaving,
    containerRef,
    isTitlePage,
    searchPerson,
    generateMsvIds,
    regenerateAutoDecoratedMetadata,
    hasButtons,
    castCrewConfig,
    seasonPersons,
    titleActionComponents,
    isFullScreen,
    formFooter,
    actions,
}) => {
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [update, setUpdate] = useState(false);
    const [validationErrorCount, setValidationErrorCount] = useState(0);
    const view = canEdit ? VIEWS.EDIT : VIEWS.VIEW;
    const {fields} = schema;
    const auth = useAuth();
    const hasPermissionsToEdit = () => auth.isAllowedTo('editTitleDetails');
    const form = useForm({mode: 'all', reValidateMode: 'onChange', defaultValues: initialData});

    React.useEffect(() => {
        const subscription = form.watch((value, {name, type}) => {
            form.trigger();
            subscription.unsubscribe();
        });
        return () => subscription.unsubscribe();
    }, [form.watch]);

    useEffect(() => {
        update && setUpdate(false);
    }, [update]);

    useEffect(() => {
        // eslint-disable-next-line prefer-destructuring
        const firstErrorElement = document.getElementsByClassName('nexus-c-field--error')[0];
        if (firstErrorElement) firstErrorElement.scrollIntoView(false);
    }, [validationErrorCount]);

    const onCancel = () => {
        actions.setRefresh(prev => !prev);
        setUpdate(true);
        setValidationErrorCount(0);
    };

    const showValidationError = () => {
        const errorsCount = document.getElementsByClassName('nexus-c-field--error').length;
        setValidationErrorCount(errorsCount);
    };

    const buttonsBuilder = (dirty, reset, errors) => {
        return (
            <>
                {errors > 0 && (
                    <div className="nexus-c-dynamic-form__validation-msg">
                        <div className="d-flex align-items-center gap-1" style={{color: '#DE350B', fontSize: '13px'}}>
                            <i className="po po-warning" />
                            <span>
                                {errors} {errors === 1 ? 'error' : 'errors'} on page
                            </span>
                        </div>
                    </div>
                )}
                {hasPermissionsToEdit() && (
                    <ButtonsBuilder
                        dirty={dirty}
                        reset={reset}
                        validationErrorCount={validationErrorCount}
                        errors={validationErrorCount}
                        disableSubmit={disableSubmit}
                        canEdit={canEdit}
                        isSaving={isSaving}
                        isEmpty={isEmpty}
                        onCancel={onCancel}
                        seasonPersons={seasonPersons}
                        showValidationError={() => showValidationError()}
                    />
                )}
            </>
        );
    };

    const validDateRange = values => {
        let areValid = true;
        const allFields = getAllFields(fields);
        Object.keys(allFields)
            .filter(key => allFields[key].type === 'dateRange')
            .forEach(key => {
                if (values[key]) {
                    const {startDate, endDate} = values[key];
                    if (moment(startDate).isAfter(endDate) || moment(endDate).isBefore(startDate)) {
                        areValid = false;
                    }
                }
            });
        return areValid;
    };

    const handleOnSubmit = (values, initialData) => {
        setValidationErrorCount(0);
        if (validDateRange(values)) {
            let correctValues = {};
            const allValues = getAllFields(fields);
            const properValues = getProperValues(fields, values);
            Object.values(allValues).forEach(({type, path}) => {
                const defaultValue = getProperValue(type, null, path, fields);
                correctValues = {...(type === 'array' && defaultValue), ...correctValues};
            });
            Object.keys(properValues).forEach(key => set(correctValues, key, properValues[key]));

            Object.keys(correctValues).forEach(k => {
                correctValues[k] =
                    Array.isArray(correctValues[k]) && !correctValues[k].length ? null : correctValues[k];
            });

            let valuesData = mergeWith({}, correctValues, (obj, src, key) => {
                // some keys should not return undefined value for arrays and instead have empty array,
                // for example when deleting all MSV association IDs, we want to return empty Array
                const excludedValues = ['msvAssociationId'];
                if (Array.isArray(src)) {
                    if (src.length || excludedValues.includes(key)) {
                        return src;
                    }
                    return undefined;
                }
                // keep original null value if updated value is object and all its properties are falsy
                // non object values are null already if not edited
                else if (obj === null && typeof src === 'object') {
                    if (!src) return null;
                    if (
                        !Object.keys(src).some(k => {
                            if (Array.isArray(src[k]))
                                // if value is array
                                return src[k].length;
                            else if (typeof src[k] === 'object' && src[k] !== null)
                                // if value is object
                                return Object.keys(src[k]).length;
                            return src[k]; // else return value
                        })
                    )
                        return null;
                }
            });

            // logic below is needed for handling the tmsIds adding/removing
            // properties with keys starting with "externalIds." inside the existing "externalIds" object
            const outputObject = Object.keys(properValues).reduce(
                (acc, key) => {
                    if (key.startsWith('externalIds.')) {
                        const newKey = key.replace('externalIds.', '');
                        if (!acc.externalIds) {
                            acc.externalIds = {};
                        }
                        if (newKey.startsWith('tmsData.')) {
                            acc.externalIds.tmsData = {
                                ids: properValues[key],
                            };
                        } else {
                            acc.externalIds[newKey] = properValues[key];
                        }
                    } else {
                        acc[key] = properValues[key];
                    }
                    return acc;
                },
                {...properValues}
            );

            const {externalIds = {}} = outputObject;
            if (!isEmpty(externalIds)) {
                valuesData = {
                    ...valuesData,
                    externalIds,
                };
            }

            onSubmit(valuesData, initialData);
        }
    };

    return (
        <div className={isFullScreen ? 'nexus-c-dynamic-form-fullscreen' : 'nexus-c-dynamic-form'}>
            <Form onSubmit={data => handleOnSubmit(data, initialData)} form={form}>
                {hasButtons && buttonsBuilder(form.formState.isDirty, () => form.reset(), validationErrorCount)}
                <div
                    ref={containerRef}
                    className={classnames('nexus-c-dynamic-form__tab-container', {
                        'nexus-c-dynamic-form__tab-container--title': isTitlePage,
                    })}
                />
                <div
                    className={classnames('nexus-c-dynamic-form__tab-content', {
                        'nexus-c-dynamic-form__tab-content--title': isTitlePage,
                    })}
                >
                    {fields.map(({title = '', sections = []}, index) => (
                        <div key={`tab-${title}`} id={`tab-${index}`} className="nexus-c-dynamic-form__section-start">
                            {sections.map(
                                (
                                    {
                                        title: sectionTitle = '',
                                        sectionID,
                                        titleActions = [],
                                        fields = {},
                                        isGridLayout = false,
                                        tabs,
                                        subTabs,
                                        prefix,
                                        resource,
                                    },
                                    sectionIndex
                                ) => (
                                    <Restricted key={`section-${sectionTitle}`} resource={resource}>
                                        {sectionTitle && (
                                            <h3 className="nexus-c-dynamic-form__section-title">{sectionTitle}</h3>
                                        )}
                                        {titleActionComponents &&
                                            titleActions.map(action =>
                                                titleActionComponents[action](
                                                    setUpdate,
                                                    () => form.getValues(),
                                                    (name, value, shouldDirty = false) => form.setValue(name, value, {shouldDirty}),
                                                    `${action}_${index}_${sectionIndex}`
                                                )
                                            )}

                                        {buildSection(
                                            fields,
                                            path => form.getValues(path),
                                            view,
                                            generateMsvIds,
                                            regenerateAutoDecoratedMetadata,
                                            {
                                                selectValues,
                                                initialData,
                                                setFieldValue: (name, value, shouldDirty = true) =>
                                                    form.setValue(name, value, {shouldDirty}),
                                                update,
                                                config: schema.config || [],
                                                isGridLayout,
                                                searchPerson,
                                                castCrewConfig,
                                                tabs,
                                                subTabs,
                                                setDisableSubmit,
                                                prefix,
                                                isTitlePage,
                                                setUpdate,
                                                sectionID,
                                                actions,
                                                triggerFormValidation: path => form.trigger(path),
                                            }
                                        )}
                                    </Restricted>
                                )
                            )}
                        </div>
                    ))}
                </div>
                {formFooter && formFooter}
            </Form>
        </div>
    );
};

NexusDynamicForm.propTypes = {
    schema: PropTypes.object,
    initialData: PropTypes.object,
    onSubmit: PropTypes.func,
    canEdit: PropTypes.bool,
    selectValues: PropTypes.object,
    containerRef: PropTypes.any,
    isTitlePage: PropTypes.bool,
    searchPerson: PropTypes.func,
    generateMsvIds: PropTypes.func,
    isSaving: PropTypes.bool,
    regenerateAutoDecoratedMetadata: PropTypes.func,
    hasButtons: PropTypes.bool,
    castCrewConfig: PropTypes.object,
    seasonPersons: PropTypes.array,
    titleActionComponents: PropTypes.object,
    isFullScreen: PropTypes.bool,
    formFooter: PropTypes.element,
    actions: PropTypes.object,
};

NexusDynamicForm.defaultProps = {
    schema: {},
    initialData: {},
    onSubmit: undefined,
    canEdit: false,
    selectValues: {},
    containerRef: null,
    isTitlePage: false,
    searchPerson: undefined,
    generateMsvIds: undefined,
    isSaving: false,
    regenerateAutoDecoratedMetadata: undefined,
    hasButtons: true,
    castCrewConfig: {},
    seasonPersons: [],
    titleActionComponents: {},
    isFullScreen: false,
    formFooter: undefined,
    actions: {},
};

export default NexusDynamicForm;
