import React from 'react';
import {Outlet} from 'react-router-dom';
import {Layout} from './layout/layout';
import dopTasksRoutes from './pages/dop-tasks/dopTasksRoutes';
import eventManagementRoutes from './pages/event-management/eventManagementRoutes';
import manualTasksRoutes from './pages/manual-tasks/manualTasksRoutes';
import servicingOrdersRoutes from './pages/servicing-orders/servicingOrdersRoutes';
import NotFound from './pages/static/NotFound';
import Welcome from './pages/static/Welcome';
import withTracker from './util/hoc/withTracker';

const Unauthorized = React.lazy(() =>
    import(
        /* webpackPrefetch: true, webpackChunkName: "ContactProfile" */ './common/components/nexus-error-boundary/Unauthorized'
    )
);

const staticRoutes = [
    {
        path: '401',
        element: <Unauthorized />,
    },
    {
        path: '*',
        element: <NotFound />,
    },
];

export const routes = [
    {
        path: ':realm',
        element: <Layout />,
        children: [
            {index: true, key: 'welcome', element: <Welcome />},
            {
                path: 'dop-tasks',
                resource: 'dopPage',
                element: <Outlet />,
                children: [...dopTasksRoutes],
            },
            {
                path: 'servicing-orders',
                resource: 'servicingOrdersView',
                element: <Outlet />,
                children: [...servicingOrdersRoutes],
            },
            {
                path: 'event-management',
                resource: 'eventManagementParentPage',
                element: <Outlet />,
                children: [...eventManagementRoutes],
            },
            ...manualTasksRoutes,
            ...staticRoutes,
        ],
    },
];

export function routesWithTracking() {
    return routes.map(route => {
        const Element = route.track ? withTracker(route.element) : undefined;
        return {...route, element: route.track ? <Element /> : route.element};
    });
}
