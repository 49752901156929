import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Button, ListBox, OverlayPanel, TagGroup, TagState, Tooltip} from '@portal/portal-components';
import {get} from 'lodash';
import {generateValidHtmlCamelCaseId} from '../../utils/utils';
import {getDir} from '../nexus-dynamic-form/utils';
import {LOCALIZED_NOT_DEFINED, NEEDS_TRANSLATION} from '../nexus-persons-list/constants';
import './NexusPerson.scss';

const NexusPerson = ({
    person,
    personCanHaveCharacterName,
    index,
    onPropagate,
    onRemove,
    onEditPerson,
    onEditCharacterName,
    emetLanguage,
    name,
    customKey,
    isTitlePage,
    isEditable,
}) => {
    const localization = get(person, 'localization');
    const isCastCrewField = name === 'castCrew';
    const op = useRef(null);

    const getEnName = () => {
        return localization ? person.displayName : person.displayNameEn;
    };

    const getNameFromLocalization = () => {
        const local = localization.find(l => l.language === emetLanguage);
        return local ? local.displayName : person.displayName;
    };

    const getLocalizedName = () => {
        return localization ? getNameFromLocalization() : person.displayName;
    };

    const isNeedTranslation =
        getLocalizedName() === getEnName() && person?.language !== undefined && emetLanguage !== person?.language;

    const localizedName = () => {
        if (person?.language === 'en' && emetLanguage === 'en') return getEnName();
        return isNeedTranslation ? NEEDS_TRANSLATION : getLocalizedName();
    };

    const hasTranslation = () => {
        return (
            localization?.some(translation => translation.language === emetLanguage) || Boolean(person?.displayNameEn)
        );
    };

    const displayName = person?.displayNameEn || person?.displayName;

    const groupedOptions = [
        {
            value: 'configuration',
            label: 'Configuration',
            icon: 'po po-config',
            isVisible: isEditable,
        },
        {
            value: 'remove',
            label: 'Remove',
            icon: 'po po-remove',
            isVisible: true,
        },
    ].filter(option => option.isVisible);

    const handleActionItems = (event = {}) => {
        const {value} = event;
        switch (value) {
            case 'configuration':
                onEditPerson();
                break;
            case 'remove':
                onRemove();
                break;
            default:
                break;
        }
    };

    const itemTemplate = (option = {}) => {
        const {label, icon} = option;
        return (
            <div className="d-flex justify-center-start w-100">
                <i className={`${icon} text-dark`} />
                <strong className="mx-3">{label}</strong>
            </div>
        );
    };

    return (
        <div className="flex-grow-1" id="personWrapperElement">
            <div className="row align-items-center justify-content-between p-2" id={person.id}>
                <div className="col-5 d-flex align-items-center gap-2">
                    <i className="po po-user po-3x" style={{color: '#b5b5b5'}} />
                    <div className="tag-wrapper mb-1 mx-2">
                        <TagGroup
                            elementId={generateValidHtmlCamelCaseId(`tagGroup_${customKey}`)}
                            tags={[{label: person.personType}]}
                            groupTagState={TagState.Neutral}
                            isReadOnly={true}
                        />
                    </div>

                    {isEditable && person.displayName === person.displayNameEn && emetLanguage !== person?.language && (
                        <span>
                            <Tooltip
                                content={LOCALIZED_NOT_DEFINED}
                                target={`#${person.id}_${index}`}
                                position="bottom"
                            />
                            <div className="nexus-c-nexus-person-warning" id={`${person.id}_${index}`} />
                        </span>
                    )}

                    <span
                        dir={getDir(localizedName())}
                        className={`d-flex col-12 ${
                            person.displayNameEn && emetLanguage !== person?.language
                                ? 'nexus-c-nexus-person-italic'
                                : ''
                        }`}
                    >
                        {localizedName()}
                        {person.characterName && (
                            <p className="nexus-c-person-character-name">{person.characterName}</p>
                        )}
                    </span>
                </div>
                <div className="col-5">
                    {hasTranslation() && emetLanguage !== 'en' && displayName && (
                        <div className="nexus-c-nexus-person-fade">
                            <span dir={getDir(displayName)}>{displayName}</span>
                        </div>
                    )}
                </div>
                <div className="col-2 nexus-c-nexus-person__buttons gap-2">
                    {personCanHaveCharacterName && (
                        <Button
                            elementId="btnEditCharacterName"
                            icon="po po-edit"
                            tooltip="Edit"
                            tooltipOptions={{position: 'bottom', appendTo: document.body}}
                            className="p-button-text"
                            onClick={e => {
                                e?.stopPropagation();
                                e?.preventDefault();
                                onEditCharacterName();
                            }}
                        />
                    )}
                    {isCastCrewField && isEditable && isTitlePage && (
                        <Button
                            elementId={`btnPropagatePerson_${index}`}
                            icon="po po-propagate"
                            tooltip="Propagate"
                            tooltipOptions={{position: 'bottom', appendTo: document.body}}
                            className="p-button-text"
                            onClick={e => {
                                e?.stopPropagation();
                                e?.preventDefault();
                                onPropagate();
                            }}
                        />
                    )}

                    <div className="separation-line-wrapper px-2">
                        <div className="separation-line h-100 w-50" />
                    </div>
                    <Button
                        elementId="actionListButton"
                        icon="po po-more"
                        className="p-button-text"
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            op && op?.current?.toggle?.(e);
                        }}
                    />
                    <OverlayPanel
                        ref={op}
                        id="optionsOverlayPanel"
                        appendTo={document.body}
                        dismissable={true}
                        breakpoints={{'960px': '75vw', '740px': '35vw', '340px': '30vw'}}
                        className="nexus-c-nexus-options-panel"
                    >
                        <ListBox
                            elementId="lsBoxItems"
                            options={groupedOptions}
                            onChange={handleActionItems}
                            optionLabel="label"
                            itemTemplate={itemTemplate}
                            style={{width: '15rem', borderWidth: '0px'}}
                            listStyle={{maxHeight: '250px'}}
                        />
                    </OverlayPanel>
                </div>
            </div>
        </div>
    );
};

NexusPerson.propTypes = {
    person: PropTypes.object.isRequired,
    personCanHaveCharacterName: PropTypes.bool,
    index: PropTypes.number.isRequired,
    onRemove: PropTypes.func,
    onPropagate: PropTypes.func,
    onEditPerson: PropTypes.func,
    onEditCharacterName: PropTypes.func,
    emetLanguage: PropTypes.string,
    name: PropTypes.string,
    customKey: PropTypes.string,
    isTitlePage: PropTypes.bool,
    isEditable: PropTypes.bool,
};

NexusPerson.defaultProps = {
    onRemove: () => null,
    onPropagate: () => null,
    onEditPerson: () => null,
    onEditCharacterName: () => null,
    emetLanguage: 'en',
    name: null,
    customKey: '',
    isTitlePage: false,
    isEditable: true,
};

export default NexusPerson;
