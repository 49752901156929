import 'styled-components';

const theme = {
    colors: {
        grays: {
            light: '#f2f2f2',
            dark: '#A9A9A9',
        },
        reds: {
            R50: '#ffebe6',
            R75: '#ffbdad',
            R100: '#ff8f73',
            R200: '#ff7452',
            R300: '#ff5630',
            R400: '#de350b',
            R500: '#bf2600',
        },
        yellows: {
            Y50: '#fffae6',
            Y75: '#fff0b3',
            Y100: '#ffe380',
            Y200: '#ffc400',
            Y300: '#ffab00',
            Y400: '#ff991f',
            Y500: '#ff8b00',
        },
        greens: {
            G50: '#e3fcef',
            G75: '#abf5d1',
            G100: '#79f2c0',
            G200: '#57d9a3',
            G300: '#36b37e',
            G400: '#00875a',
            G500: '#006644',
        },
        blues: {
            B50: '#deebff',
            B75: '#b3d4ff',
            B100: '#4c9aff',
            B200: '#2684ff',
            B300: '#0065ff',
            B400: '#0052cc',
            B500: '#0747a6',
        },
        purples: {
            P50: '#eae6ff',
            P75: '#c0b6f2',
            P100: '#998dd9',
            P200: '#8777d9',
            P300: '#6554c0',
            P400: '#5243aa',
            P500: '#403294',
        },
        teals: {
            T50: '#e6fcff',
            T75: '#b3f5ff',
            T100: '#79e2f2',
            T200: '#00c7e6',
            T300: '#00b8d9',
            T400: '#00a3bf',
            T500: '#008da6',
        },
        neutrals: {
            N0: '#ffffff',
            N10: '#fafbfc',
            N20: '#f4f5f7',
            N30: '#ebecf0',
            N40: '#dfe1e6',
            N50: '#c1c7d0',
            N60: '#b3bac5',
            N70: '#a5adba',
            N80: '#97a0af',
            N90: '#8993a4',
            N100: '#7a869a',
            N200: '#6b778c',
            N300: '#5e6c84',
            N400: '#505f79',
            N500: '#42526e',
            N600: '#344563',
            N700: '#253858',
            N800: '#172b4d',
            N900: '#091e42',
            N10A: 'rgba(9, 30, 66, 0.02)',
            N20A: 'rgba(9, 30, 66, 0.04)',
            N30A: 'rgba(9, 30, 66, 0.08)',
            N40A: 'rgba(9, 30, 66, 0.13)',
            N50A: 'rgba(9, 30, 66, 0.25)',
            N60A: 'rgba(9, 30, 66, 0.31)',
            N70A: 'rgba(9, 30, 66, 0.36)',
            N80A: 'rgba(9, 30, 66, 0.42)',
            N90A: 'rgba(9, 30, 66, 0.48)',
            N100A: 'rgba(9, 30, 66, 0.54)',
            N200A: 'rgba(9, 30, 66, 0.6)',
            N300A: 'rgba(9, 30, 66, 0.66)',
            N400A: 'rgba(9, 30, 66, 0.71)',
            N500A: 'rgba(9, 30, 66, 0.77)',
            N600A: 'rgba(9, 30, 66, 0.82)',
            N700A: 'rgba(9, 30, 66, 0.89)',
            N800A: 'rgba(9, 30, 66, 0.95)',
        },
        darkModeNeutrals: {
            DN900: ' #e6edfa',
            DN800: ' #dce5f5',
            DN700: ' #ced9eb',
            DN600: ' #b8c7e0',
            DN500: ' #abbbd6',
            DN400: ' #9fb0cc',
            DN300: ' #8c9cb8',
            DN200: ' #7988a3',
            DN100: ' #67758f',
            DN90: ' #56637a',
            DN80: ' #455166',
            DN70: ' #3b475c',
            DN60: ' #313d52',
            DN50: ' #283447',
            DN40: ' #202b3d',
            DN30: ' #1b2638',
            DN20: ' #121a29',
            DN10: ' #0e1624',
            DN0: ' #0d1424',
            DN800A: 'rgba(13, 20, 36, 0.06)',
            DN700A: 'rgba(13, 20, 36, 0.14)',
            DN600A: 'rgba(13, 20, 36, 0.18)',
            DN500A: 'rgba(13, 20, 36, 0.29)',
            DN400A: 'rgba(13, 20, 36, 0.36)',
            DN300A: 'rgba(13, 20, 36, 0.4)',
            DN200A: 'rgba(13, 20, 36, 0.47)',
            DN100A: 'rgba(13, 20, 36, 0.53)',
            DN90A: 'rgba(13, 20, 36, 0.63)',
            DN80A: 'rgba(13, 20, 36, 0.73)',
            DN70A: 'rgba(13, 20, 36, 0.78)',
            DN60A: 'rgba(13, 20, 36, 0.81)',
            DN50A: 'rgba(13, 20, 36, 0.85)',
            DN40A: 'rgba(13, 20, 36, 0.89)',
            DN30A: 'rgba(13, 20, 36, 0.92)',
            DN20A: 'rgba(13, 20, 36, 0.95)',
            DN10A: 'rgba(13, 20, 36, 0.97)',
        },
    },
};

export default theme;
