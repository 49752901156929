import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Checkbox, RadioButton} from '@portal/portal-components';
import {isEmpty} from 'lodash';
import {ProgressSpinner} from 'primereact/progressspinner';
import {useSelector} from 'react-redux';
import './PropagateForm.scss';
import {ADVERTISEMENT} from '../../../../constants/contentType';
import {searchPersonById} from '../../../../utils/services/rightDetailsServices';
import {checkIfEmetIsEditorial} from '../../../nexus-dynamic-form/utils';
import {
    CANCEL_BUTTON,
    CAST_CREW,
    EDITORIAL,
    EDITORIAL_METADATA,
    EMETS,
    EMPTY_CAST_CREW,
    EMPTY_EMETS,
    EPISODE,
    PROPAGATE,
    SEASON,
} from './propagateConstants';

const propagateAddPersonsSelector = state => state?.titleMetadata?.propagateAddPersons || [];
const propagateRemovePersonsSelector = state => state?.titleMetadata?.propagateRemovePersons || [];

export const PROPAGATE_ADD_PERSONS = 'PROPAGATE_ADD_PERSONS';

// const propagateAddPersons = payload => ({
//     type: PROPAGATE_ADD_PERSONS,
//     payload,
// });

const PropagateForm = ({getValues, setFieldValue, person, onClose}) => {
    // const dispatch = useDispatch();
    const [checkedEmet, setCheckedEmet] = useState(true);
    const [radioValue, setRadioValue] = useState('none');
    const [isLoading, setIsLoading] = useState(false);
    const [localizationCastCrew, setLocalizationCastCrew] = useState([]);
    const propagateAddedPersons = useSelector(propagateAddPersonsSelector);
    const propagateRemovePersons = useSelector(propagateRemovePersonsSelector);

    const {castCrew, contentType, editorialMetadata} = getValues();
    const persons = isEmpty(person) ? castCrew : [person];
    const isCastCrewEmpty = !castCrew?.length;
    const isEMetsEmpty = !editorialMetadata?.length;

    const contentTypeUpperCase = contentType ? contentType.toUpperCase() : '';

    useEffect(() => {
        async function fetchLocalizationPersons() {
            setIsLoading(true);
            const allLocalizationsPersons = persons.map(async ({id}) => {
                try {
                    return await searchPersonById(id);
                } catch (err) {
                    return;
                }
            });

            const localizationPersons = await Promise.all(allLocalizationsPersons);
            setLocalizationCastCrew(localizationPersons);
            setIsLoading(false);
        }
        !isEmpty(persons) && fetchLocalizationPersons();
    }, [castCrew]);

    const getPropagateMessage = () => `Propagate ${isEmpty(person) ? CAST_CREW : person.displayName} to...`;

    const generateCastCrewToPropagate = emet => {
        // Propagation characterName on core to emets should not happen
        const newPersonsList = persons.map(({characterName, ...rest}) => rest);
        const uniquePersons = newPersonsList.filter(person => {
            return isEmpty(emet.castCrew)
                ? person
                : !emet.castCrew.some(
                      emetPerson => emetPerson.id === person.id && emetPerson.personType === person.personType
                  );
        });

        const localizedUniquePersons = uniquePersons.map(person => {
            const localization = localizationCastCrew.find(({id}) => id === person.id)?.localization;

            return {
                ...person,
                localization,
            };
        });

        const newCastCrew = isEmpty(emet.castCrew)
            ? localizedUniquePersons
            : [...emet.castCrew, ...localizedUniquePersons];

        const updatedEmet = {
            ...emet,
            castCrew: newCastCrew,
        };
        if (checkIfEmetIsEditorial(emet, editorialMetadata)) {
            setFieldValue(EDITORIAL, {...editorialMetadata, castCrew: newCastCrew});
        }

        return updatedEmet;
    };

    const handleAddEmetOption = async () => {
        const updateEditorialMetadata = editorialMetadata.map(emet => generateCastCrewToPropagate(emet));

        setFieldValue(EDITORIAL_METADATA, updateEditorialMetadata, true);
    };

    const handleAddSeasonOption = () => {
        const seasonCastCrewPropagateData = persons.map(person => {
            const {id, personType, order} = person;
            return {
                id,
                personType,
                order,
                propagateToEmet: radioValue === 'emets',
            };
        });

        const payload = {
            added: [...propagateAddedPersons, ...seasonCastCrewPropagateData],
            removed: propagateRemovePersons.filter(person => {
                return !seasonCastCrewPropagateData.some(
                    entry => entry.id === person.id && entry.personType === person.personType
                );
            }),
        };

        setFieldValue('personsAdded', payload.added);
        setFieldValue('personsRemoved', payload.removed);

        // dispatch(propagateAddPersons(payload));
    };

    const handleAdd = async () => {
        checkedEmet && !isCastCrewEmpty && !isEMetsEmpty && await handleAddEmetOption();
        radioValue !== 'none' && handleAddSeasonOption();

        onClose();
    };

    const onChange = useCallback(event => {
        setRadioValue(event.target.value);
    }, []);

    const episodePropagateOptions = [
        {
            label: 'None',
            value: 'none',
            checked: radioValue === 'none',
            name: 'none',
            onChange,
            disabled: isCastCrewEmpty,
        },
        {
            label: 'Core',
            value: 'core',
            checked: radioValue === 'core',
            name: 'core',
            onChange,
            disabled: isCastCrewEmpty,
        },
        {
            label: 'Core & EMets',
            value: 'emets',
            checked: radioValue === 'emets',
            name: 'emets',
            onChange,
            disabled: isCastCrewEmpty,
        },
    ];

    const errorMessage = message => {
        return (
            <div className="d-flex align-items-center gap-1" style={{color: '#DE350B', fontSize: '13px'}}>
                <i className="po po-warning" />
                <span>{message}</span>
            </div>
        );
    };

    return (
        <>
            <p className="propagate-form__message">{getPropagateMessage()}</p>
            {isLoading ? (
                <div className="row">
                    <div className="col-12 text-center">
                        <ProgressSpinner
                            className="nexus-c-data-panel__spinner"
                            strokeWidth="4"
                            animationDuration=".5s"
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div className="propagate-form__section">
                        <h5>
                            {contentTypeUpperCase !== ADVERTISEMENT.apiName ? contentTypeUpperCase : ADVERTISEMENT.name}
                        </h5>
                        <Checkbox
                            elementId="emets"
                            inputId="emets"
                            checked={checkedEmet}
                            onChange={() => setCheckedEmet(!checkedEmet)}
                            disabled={isCastCrewEmpty || isEMetsEmpty}
                            labelProps={{
                                label: EMETS,
                            }}
                            labelPosition="right"
                        />
                    </div>
                    {contentTypeUpperCase === SEASON && (
                        <>
                            <hr className="solid" />
                            <div className="propagate-form__section">
                                <h5>{EPISODE}</h5>
                                <div className="propagate-form__radio">
                                    <RadioButton
                                        elementId="propagateFormRadioBtn"
                                        labelProps={{
                                            label: EPISODE,
                                            stacked: true,
                                            shouldUpper: false,
                                        }}
                                        options={episodePropagateOptions}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}

            <div className="propagate-form__error">
                {isCastCrewEmpty && errorMessage(EMPTY_CAST_CREW)}
                {isEMetsEmpty && errorMessage(EMPTY_EMETS)}
            </div>
            <div className="propagate-form__actions">
                <Button
                    elementId="btnCancelPropagation"
                    label={CANCEL_BUTTON}
                    className="p-button-outlined p-button-secondary"
                    onClick={() => onClose()}
                />
                <Button
                    elementId="btnPropagateAction"
                    label={PROPAGATE}
                    className="p-button-outlined propagate-form__propagate-button"
                    onClick={handleAdd}
                    disabled={
                        (radioValue === 'none' && isEMetsEmpty) ||
                        isCastCrewEmpty ||
                        isLoading ||
                        (radioValue === 'none' && !checkedEmet)
                    }
                />
            </div>
        </>
    );
};

PropagateForm.propTypes = {
    person: PropTypes.object,
    getValues: PropTypes.func,
    setFieldValue: PropTypes.func,
    onClose: PropTypes.func.isRequired,
};

PropagateForm.defaultProps = {
    person: {},
    getValues: () => null,
    setFieldValue: () => null,
};

export default PropagateForm;
