import React, { useState } from 'react';
import { logout } from '@portal/portal-auth/authActions';
import { Layout as PortalLayout, UserProfile } from '@portal/portal-components';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import menuOptions from '../config/menuOptions.json';
import { URL } from '../common/utils/Common';
import DOP from '../common/utils/DOP';
import { getConfig } from '../common/utils/config';
import { TenantSelect } from './tenant-select/tenantSelect';
import { calculateExternalUrl } from './utils';
import './layout.scss';

export const Layout = () => {
    const profileInfo = useSelector((state) => state?.auth?.userAccount);
    const selectedTenant = useSelector((state) => state?.auth?.selectedTenant);
    const [selectedMenuOpt, setSelectedMenuOpt] = useState(undefined);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onMenuItemClicked = (item) => {
        switch (item.type) {
            case 'external':
                window.open(calculateExternalUrl(item), '_blank', 'noreferrer');
                break;
            default:
                navigate(item.path);
                break;
        }
        setSelectedMenuOpt(item);
    };

    const onSelectMenuItem = (item) => {
        setSelectedMenuOpt(item);
    };

    const topBarActions = () => {
        return (
            <div className="row w-100">
                <div className="col-5 offset-4 col-sm-8 offset-sm-1">
                    <TenantSelect />
                </div>
                <div className="col-1 text-center">
                    <div className="separation-line-wrapper py-1 h-100">
                        <div className="separation-line h-100 w-50" />
                    </div>
                </div>
                <div className="col-2 text-end">{renderUserProfile()}</div>
            </div>
        );
    };

    const renderUserProfile = () => (
        <UserProfile id="ddlUserProfile">
            <div className="profile-contents my-2 mx-3">
                <div className="profile-name" id="infoProfileName">
                    {`${profileInfo?.firstName} ${profileInfo?.lastName}` || 'Profile'}
                </div>
                <div className="profile-username" id="infoProfileUsername">{`${profileInfo?.username}`}</div>
                <div className="profile-app-version" id="infoAppVersion">{`v${getConfig('portalVersion')}`}</div>
            </div>
            <div className="profile-content-actions mt-2">
                <div
                    className="logout d-flex align-items-center py-3 px-3"
                    id="btnLogout"
                    onClick={() => dispatch(logout())}
                >
                    <i className="po po-logout" />
                    <div className="d-inline mx-2">Log Out</div>
                </div>
            </div>
        </UserProfile>
    );

    return (
        <PortalLayout
            showTopBar={!URL.isEmbedded()}
            showMenu={!URL.isEmbedded()}
            menuItems={menuOptions.menuItems}
            onMenuItemClicked={onMenuItemClicked}
            onLoadSelectMenuItem={onSelectMenuItem}
            topBarOptions={{
                topBarHeading: selectedMenuOpt?.label || '',
                topBarActions: topBarActions(),
                topBarClass: selectedTenant.id !== 'VU' ? 'non-vu-tenant' : undefined,
            }}
        >
            {/* Inject Dop.js for external embedded frames */}
            {URL.isEmbedded() ? <DOP /> : null}
            <Outlet />
        </PortalLayout>
    );
};
