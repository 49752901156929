import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useAuth} from '@portal/portal-auth';
import {PortalAutoComplete, TagGroup, TagState} from '@portal/portal-components';
import {toString, toUpper} from 'lodash';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import './TagManagement.scss';
import {getAuthConfig} from '../../../../../../utils/config';

/**
 *
 * @param tags array with object containing the tag chips
 * @param shouldUpper boolean for the text inside the chips
 * @param setFieldValue callback for handling the path and tagList on change
 * @param path string that hold the API path
 * @param elementId portal-components ID
 * @param tagState has 5 values for the badges -> error, success etc. + one default
 * @param colClasses bootstrap column classes to set them dynamically as a prop (default values / example provided in the propTypes)
 * @returns {JSX.Element}
 */

const TagManagement = ({tags, shouldUpper, setFieldValue, path, elementId, tagState, colClasses}) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const tenantTags = useSelector(state => state?.titleMetadata?.tenantTags);
    const allowTagEdit = auth.isAllowedTo('editTitleTags');
    const [tagList, updateTagList] = useState(tags || []);
    const [showAddButton, setShowAddButton] = useState(true);
    const [filteredOptions, setFilteredOptions] = useState(tenantTags || []);
    const [currentInput, setCurrentInput] = useState('');

    useEffect(() => {
        // setFieldValue is used for building section on NexusDynamic form, that is why we need path
        setFieldValue(path, tagList || undefined);
    }, [tagList]);

    useEffect(() => {
        let exactMatchExists = false;
        if (filteredOptions.indexOf(toUpper(currentInput)) >= 0) {
            exactMatchExists = true;
        }
        filteredOptions.length === 0 || !exactMatchExists ? setShowAddButton(true) : setShowAddButton(false);
    }, [filteredOptions]);

    const handleTagRemove = tagLabel => {
        const newTagList = tagList?.filter(item => toUpper(toString(item)) !== toUpper(toString(tagLabel)));
        updateTagList(newTagList || []);
    };

    /**
     * Return an array of tags with unique inputs
     * @param {string} value
     */
    const handleFooterOnClick = value => {
        updateTagList(oldArray => {
            return [
                shouldUpper ? toUpper(value) : value || '',
                ...oldArray.map(x => (shouldUpper ? toUpper(x) : x)),
            ].filter((val, index, arr) => arr.indexOf(val) === index);
        });
    };

    const onCompleteMethod = value => {
        const inputValue = value || '';
        setCurrentInput(inputValue);
        setFilteredOptions(tenantTags.filter(item => toUpper(item).includes(toUpper(inputValue))));
    };

    const handleOnChange = value => {
        const filteredValue = [value || '', ...tagList].filter(
            (val, index, tagArray) => tagArray.indexOf(val) === index
        );
        updateTagList(filteredValue);
    };

    const handleTagClick = tagName => {
        const pathname = `/${getAuthConfig().realm}/metadata`;
        const search = `?tag=${tagName}`;
        navigate({pathname, search});
    };

    return (
        <div className="row" id="PortalAutoCompleteRow" style={{position: 'relative'}}>
            {allowTagEdit && (
                <div className={colClasses?.autoCompleteClass}>
                    <PortalAutoComplete
                        clearAutoComplete={true}
                        elementId="tagManagementAutoComplete"
                        disabled={false}
                        placeholder="Add a tag..."
                        stickyFooterButton={
                            showAddButton && {
                                icon: 'po po-add-tag',
                                clearTextInputValue: true,
                                onClick: handleFooterOnClick,
                            }
                        }
                        placeholderIcon="po po-tag"
                        clearValueAfterChange={true}
                        options={filteredOptions}
                        completeMethod={event => onCompleteMethod(event)}
                        onChange={handleOnChange}
                        appendTo={document.getElementById('PortalAutoCompleteRow')}
                    />
                </div>
            )}
            <div className={`${allowTagEdit ? colClasses?.tagsClass : 'col-12'}`}>
                <TagGroup
                    tags={tagList}
                    shouldUpper={shouldUpper}
                    handleRemove={handleTagRemove}
                    isReadOnly={!allowTagEdit}
                    elementId={`TagGroup_${elementId}`}
                    groupTagState={tagState}
                    onTagClick={handleTagClick}
                />
            </div>
        </div>
    );
};

TagManagement.propTypes = {
    tags: PropTypes.array.isRequired,
    shouldUpper: PropTypes.bool,
    setFieldValue: PropTypes.func,
    path: PropTypes.string,
    colClasses: PropTypes.object,
    elementId: PropTypes.string.isRequired,
    tagState: PropTypes.string,
};

TagManagement.defaultProps = {
    shouldUpper: true,
    setFieldValue: () => null,
    path: '',
    colClasses: {autoCompleteClass: 'col-4', tagsClass: 'col-8'},
    tagState: TagState.Default,
};

export default TagManagement;
