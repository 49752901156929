import React from 'react';
import { keycloak } from '@portal/portal-auth';
import { setSelectedTenantInfo } from '@portal/portal-auth/authActions';
import { updateLocalStorageWithSelectedTenant } from '@portal/portal-auth/utils';
import { Dropdown } from '@portal/portal-components';
import { useDispatch, useSelector } from 'react-redux';
import './tenantSelect.scss';
import { setSelectedTitles } from '../../pages/title-metadata/titleMetadataActions';
import { getTenantOptions } from './utils';

export const TenantSelect = () => {
    const dispatch = useDispatch();
    const currentLoggedInUsername = useSelector((state) => state?.auth?.userAccount?.username);
    const selectedTenant = useSelector((state) => state?.auth?.selectedTenant);
    const { realmAccess } = keycloak || {};

    /**
     * onChange function when selecting a tenant from the Dropdown list
     * transforms key:value array to object
     * updated Redux with the selected tenant for this session
     * update LocalStorage with this user's selected tenant
     * clean selected titles from the previous tenant
     * @param {string} id - [0] - id, [1] - roles[]
     */
    const onTenantChange = (id) => {
        const selectedTenant = getTenantOptions().find((t) => t.id === id);
        const tempSelectedTenant = { id: selectedTenant.id, roles: selectedTenant.roles };
        tempSelectedTenant.roles = [...tempSelectedTenant.roles, ...realmAccess.roles, tempSelectedTenant.id];
        dispatch(setSelectedTenantInfo(tempSelectedTenant));
        dispatch(setSelectedTitles({ [currentLoggedInUsername]: [] }));
        updateLocalStorageWithSelectedTenant(currentLoggedInUsername, tempSelectedTenant);
    };

    const valueTemplate = (option, props) => {
        if (option) {
            return (
                <div className="selected-tenant-value d-flex align-items-center">
                    <i className="po po-multitenancy" />
                    <span className="mx-3">{option.label}</span>
                </div>
            );
        }

        return <span>Select... </span>;
    };

    return (
        <Dropdown
            elementId="ddlSelectTenant"
            options={getTenantOptions()}
            valueTemplate={valueTemplate}
            value={selectedTenant?.id}
            optionLabel="label"
            dropdownIcon="po po-expand-section"
            optionValue="id"
            columnClass="col-12"
            className="tenant-select"
            onChange={(e) => onTenantChange(e.value)}
        />
    );
};
