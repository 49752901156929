import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Label} from '@portal/portal-components';
import {connect} from 'react-redux';
import {VIEWS} from '../constants';

const withOptionalCheckbox = () => WrappedComponent => {
    const ComposedComponent = props => {
        const {
            isOptional,
            useCurrentDate,
            label,
            isRequired,
            setFieldValue,
            path,
            name,
            view,
            territoryLenght,
            ...fieldProps
        } = props;
        const {value, isDisabled, ...restFieldProps} = fieldProps;
        const initialCheckValue = !!(value && view !== VIEWS.CREATE);
        const [checked, setChecked] = useState(initialCheckValue);
        const [isReadOnly, setIsReadOnly] = useState(isOptional);

        // remove dispatch when isOptional = false and check for value - fix react warnings
        const {dispatch, value: val, ...propsWithoutDispatch} = fieldProps;

        useEffect(() => {
            setChecked(initialCheckValue);
        }, [territoryLenght]);

        const changeCheckboxValue = e => {
            const checkValue = e.checked;

            fieldProps?.onChange?.(checkValue ? (useCurrentDate ? new Date() : value || '') : '');
            // withdrawn date is readOnly when populated (when empty, user can populate it using checkbox)
            const hasWithDrawnDate = name.includes('dateWithdrawn');
            setIsReadOnly((hasWithDrawnDate && checkValue) || fieldProps?.isReadOnly);
            setChecked(checkValue);
        };

        return isOptional ? (
            <div className="nexuc-c-with-optional">
                <Label label={label} shouldUpper={true} stacked={false} isRequired={isRequired} />
                <div className="row">
                    <div className="col-auto">
                        <Checkbox
                            elementId={`chkbxOptional${path}`}
                            checked={checked}
                            onChange={changeCheckboxValue}
                            disabled={isDisabled}
                        />
                    </div>
                    <div className="col-auto">
                        {checked && <WrappedComponent {...restFieldProps} path={path} isReadOnly={isReadOnly} />}
                    </div>
                </div>
            </div>
        ) : (
            <WrappedComponent {...propsWithoutDispatch} />
        );
    };

    ComposedComponent.propTypes = {
        ...WrappedComponent.propTypes,
        isOptional: PropTypes.bool,
        maxLength: PropTypes.number,
        value: PropTypes.any,
    };

    ComposedComponent.defaultProps = {
        ...WrappedComponent.defaultProps,
        isOptional: false,
        maxLength: undefined,
        value: '',
    };

    return connect()(ComposedComponent);
};

export default withOptionalCheckbox;
