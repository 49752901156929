import React from 'react';
import {Button} from '@portal/portal-components';

export const arrayElementButtons = (
    index,
    fieldsLength,
    onAdd,
    onRemove,
    shouldAddMargin = false,
    disabled = false
) => {
    return (
        <div className={`col-1 text-center ${shouldAddMargin ? 'nexus-c-col-margin-bottom' : ''}`}>
            {fieldsLength > 1 && (
                <Button
                    elementId="btnRemoveElement"
                    className="p-button-text"
                    icon="po po-remove"
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        onRemove();
                    }}
                    disabled={disabled}
                />
            )}
        </div>
    );
};
