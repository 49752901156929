import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Chips, InputNumber, InputText, InputTextarea, Label} from '@portal/portal-components';
import {get, isNil, toLower} from 'lodash';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {compose} from 'redux';
import {isObject} from '../../../../utils/Common';
import {
    RELATIVE_DATE_FORMAT,
    RELATIVE_DATE_FORMAT_WITHOUT_TIME,
    TIMESTAMP_DATE_FORMAT,
} from '../../../nexus-date-and-time-elements/constants';
import ErrorBoundary from '../../../nexus-error-boundary/ErrorBoundary';
import NexusSelect from '../../../nexus-select/NexusSelect';
import {DATETIME_FIELDS, LOCALIZED_VALUE_NOT_DEFINED, VIEWS} from '../../constants';
import withOptionalCheckbox from '../../hoc/withOptionalCheckbox';
import {checkFieldDependencies, createUrl, getDir, getFieldValue, getValidationFunction, hebrew} from '../../utils';
import CastCrew from './components/CastCrew/CastCrew';
import DateTime from './components/DateTime/DateTime';
import MsvIds from './components/MsvIds/MsvIds';
import './NexusField.scss';
import RowDataItem from './components/RowDataItem/RowDataItem';
import TagManagement from './components/TagManagement/TagManagement';

const DateTimeWithOptional = compose(withOptionalCheckbox())(DateTime);

const InputTextAreaWithOptional = compose(withOptionalCheckbox())(InputTextarea);

const CheckboxWithOptional = compose(withOptionalCheckbox())(Checkbox);

const TextFieldWithOptional = compose(withOptionalCheckbox())(InputText);

const NexusField = ({
    isHighlighted,
    selectValues,
    path,
    type,
    view,
    tooltip,
    isRequiredVZ,
    oneIsRequiredVZ,
    formData,
    getValues,
    isReadOnly,
    isReadOnlyInEdit,
    isRequired,
    dependencies,
    validationError,
    validation,
    dateType,
    labels,
    optionsConfig,
    label,
    isOptional,
    maxLength,
    setFieldValue,
    useCurrentDate,
    getCurrentValues,
    isReturningTime,
    config,
    isEditable,
    isGridLayout,
    isVerticalLayout,
    searchPerson,
    castCrewConfig,
    generateMsvIds,
    setDisableSubmit,
    initialData,
    linkConfig,
    showLocalized,
    localizationConfig,
    setUpdatedValues,
    isClearable,
    isTitlePage,
    setUpdate,
    allData,
    forMetadata,
    shouldUpperCase,
    pathName,
    sectionID,
    shouldStackLabel,
    rows,
    cols,
    triggerFormValidation,
    isModal,
    stackLabel,
    ...props
}) => {
    const checkDependencies = type => {
        return checkFieldDependencies(type, view, dependencies, {
            formData,
            config,
            isEditable,
            getCurrentValues,
        });
    };

    const addedProps = {
        isOptional,
        setFieldValue,
        path,
        view,
        maxLength,
        pathName,
        rows,
        cols,
    };

    const getLanguage = () => {
        if (path?.includes('editorialMetadata')) {
            const pathParts = path.split('.');
            const newPath = `${pathParts[0]}.${pathParts[1]}.language`;
            return getValues(newPath);
        }
        return 'en';
    };

    const emetLanguage = getLanguage();
    const sasktelArray = formData.editorialMetadata?.tenantData?.simpleProperties || [];
    const shortTitleTemplate =
        formData.editorialMetadata?.tenantData?.complexProperties?.find(e => e.simpleProperties)?.simpleProperties ||
        [];
    const newShowLocalized = emetLanguage?.value === 'en' ? false : showLocalized;

    const getIsReadOnly = () => {
        return (isReadOnlyInEdit && view === VIEWS.EDIT) || isReadOnly;
    };

    const dateProps = {
        isDisabled: checkDependencies('readOnly'),
        labels,
        type,
        dateType,
        isReadOnly: getIsReadOnly() || checkDependencies('readOnly'),
        useCurrentDate,
        isReturningTime,
        isClearable,
        ...addedProps,
    };

    useEffect(() => {
        if (validationError) {
            triggerFormValidation?.(path);
        }
    }, [validationError]);

    const generateElementIds = (fieldProps, addedProps) => {
        if (fieldProps) {
            const elementView = toLower(addedProps.view);
            const constructionArray = [sectionID, elementView, fieldProps.id];
            return constructionArray.join('.');
        }
        return '';
    };

    /**
     * looks for pathName flag in schema.json and if its present, will find the value from an array.
     * @param addedProps extra props added statically from the schema.json; the flags
     */
    const findValue = addedProps => {
        if (
            addedProps?.path === 'tenantData.simpleProperties' &&
            typeof getValues(path) === 'object' &&
            getValues(path) !== null
        ) {
            const newFieldValue = getValues(path).find(x => x.name === addedProps.pathName);
            return newFieldValue?.value;
        }

        if (addedProps.path === 'tenantData.complexProperties') {
            if (Array.isArray(getValues(path))) {
                return getValues(path)
                    .find(e => e.simpleProperties)
                    ?.simpleProperties.find(e => e.name === 'shortTitleTemplate')?.value;
            } else if (typeof getValues(path) === 'string') {
                shortTitleTemplate?.map(e => (e.name === 'shortTitleTemplate' ? (e.value = getValues(path)) : e.value));
            }
        }

        if (addedProps?.pathName && typeof getValues(path) === 'string') {
            sasktelArray.forEach(e => {
                if (e.name === addedProps.pathName) {
                    e.value = getValues(path);
                    formData.editorialMetadata.tenantData.simpleProperties = sasktelArray;
                }
            });
        }
        return getValues(path);
    };

    const getPath = () => {
        if (path.includes('complexProperties')) {
            const shortTitleIndex = getValues(path).findIndex(x => x.name === props.originalPath);

            return `${path}.${shortTitleIndex}.value`;
        } else if (path.includes('simpleProperties')) {
            const initPath = `${path.split('simpleProperties')[0]}simpleProperties`;
            const initValues = getValues(initPath) || [];

            const sasktelInventoryIdValue = initValues?.find(x => x.name === 'sasktelInventoryId')?.value;
            const sasktelLineupIdValue = initValues?.find(x => x.name === 'sasktelLineupId')?.value;

            const sasktelValues = [
                {
                    name: 'sasktelInventoryId',
                    value: sasktelInventoryIdValue || '',
                },
                {
                    name: 'sasktelLineupId',
                    value: sasktelLineupIdValue || '',
                },
            ];

            let valuePath;

            if (addedProps.pathName.includes('sasktelInventoryId')) {
                valuePath = `${path.split('simpleProperties')[0]}simpleProperties.0.value`;
            } else if (addedProps.pathName.includes('sasktelLineupId')) {
                valuePath = `${path.split('simpleProperties')[0]}simpleProperties.1.value`;
            }

            if (isNil(sasktelInventoryIdValue) || isNil(sasktelLineupIdValue)) {
                setFieldValue(`${valuePath.split('simpleProperties')[0]}simpleProperties`, sasktelValues, false);
            }

            return `${valuePath}`;
        } else if (addedProps?.pathName && typeof getValues(path) === 'string') {
            sasktelArray.forEach(e => {
                if (e.name === addedProps.pathName) {
                    e.value = getValues(path);
                    formData.editorialMetadata.tenantData.simpleProperties = sasktelArray;
                }
            });
        } else {
            return path;
        }
    };

    const getFormRules = () => {
        return {
            required: {value: isRequired, message: 'Field cannot be empty!'},
            validate: value =>
                getValidationFunction(value, validation, {
                    type,
                    isRequired: checkDependencies('required') || isRequired,
                    getCurrentValues,
                }),
        };
    };

    const renderFieldEditMode = fieldProps => {
        const selectFieldProps = {...fieldProps};
        const multiselectFieldProps = {...fieldProps};
        let selectLocalizedValues = null;
        let newOptionsConfig = null;
        const value = getValues(path);

        switch (type) {
            case 'chips': {
                return (
                    <Chips
                        key={path}
                        elementId={generateElementIds(fieldProps, addedProps)}
                        labelProps={{
                            label,
                            shouldUpper: true,
                            stacked: shouldStackLabel,
                            isRequired,
                            columnClass: 'col-4',
                        }}
                        columnClass="col-8"
                        formControlOptions={{
                            formControlName: getPath(),
                            rules: getFormRules(),
                        }}
                        separator=","
                        addOnBlur={true}
                        placeholder={value && value.length > 0 ? '' : `Enter ${label}`}
                    />
                );
            }

            case 'string':
            case 'link':
                return (
                    <TextFieldWithOptional
                        key={path}
                        elementId={generateElementIds(fieldProps, addedProps)}
                        labelProps={{
                            label,
                            shouldUpper: true,
                            stacked: shouldStackLabel,
                            isRequired,
                            columnClass: 'col-4',
                        }}
                        columnClass="col-8"
                        formControlOptions={{
                            formControlName: getPath(),
                            rules: getFormRules(),
                        }}
                        {...addedProps}
                        placeholder={`Enter ${label}`}
                        dir={getDir(value)}
                    />
                );
            case 'textarea':
                return (
                    <InputTextAreaWithOptional
                        labelProps={{
                            label,
                            shouldUpper: true,
                            stacked: shouldStackLabel,
                            isRequired,
                            columnClass: 'col-4',
                        }}
                        key={path}
                        columnClass="col-8"
                        elementId={generateElementIds(fieldProps, addedProps)}
                        formControlOptions={{
                            formControlName: path,
                            rules: getFormRules(),
                        }}
                        {...addedProps}
                        placeholder={`Enter ${label}`}
                        dir={getDir(value)}
                    />
                );
            case 'number': {
                return (
                    <InputNumber
                        labelProps={{
                            label,
                            shouldUpper: true,
                            stacked: shouldStackLabel,
                            isRequired,
                            columnClass: 'col-4',
                        }}
                        key={path}
                        columnClass="col-8"
                        placeholder={`Enter ${label}`}
                        elementId={generateElementIds(fieldProps, addedProps)}
                        formControlOptions={{
                            formControlName: path,
                            rules: getFormRules(),
                        }}
                        showButtons={true}
                        {...fieldProps}
                    />
                );
            }
            case 'boolean':
                return (
                    <CheckboxWithOptional
                        key={path}
                        {...props}
                        {...addedProps}
                        elementId={generateElementIds(fieldProps, addedProps)}
                        formControlOptions={{
                            formControlName: path,
                            rules: getFormRules(),
                        }}
                        labelProps={{
                            label,
                            shouldUpper: true,
                            stacked: false,
                            isRequired,
                        }}
                        labelPosition="left"
                        labelClassName="col-4 ms-2"
                        disabled={getIsReadOnly() || checkDependencies('readOnly')}
                    />
                );
            case 'select':
                if (get(fieldProps, 'value.value', undefined) === undefined) {
                    selectFieldProps.value = {
                        label: value,
                        value,
                    };
                }
                // set label to full text string (not code). label is used in select as display text
                if (/locale/i.test(fieldProps.name) || /countryOfOrigin/i.test(fieldProps.name)) {
                    const selectVal = getValueFromSelectValues('country', value);
                    selectFieldProps.value =
                        typeof selectVal === 'string'
                            ? {
                                  label: selectVal,
                                  value,
                              }
                            : selectVal;
                } else if (/language/i.test(fieldProps.name)) {
                    const selectVal = getValueFromSelectValues('language', value);
                    selectFieldProps.value =
                        typeof selectVal === 'string'
                            ? {
                                  label: selectVal,
                                  value,
                              }
                            : selectVal;
                }

                return (
                    <>
                        <NexusSelect
                            key={path}
                            fieldProps={{
                                formControlOptions: {
                                    formControlName: path,
                                    rules: getFormRules(),
                                },
                                labelProps: {
                                    label,
                                    shouldUpper: true,
                                    stacked: shouldStackLabel,
                                    isRequired,
                                    columnClass: 'col-4',
                                },
                                columnClass: 'col-8',
                            }}
                            id={generateElementIds(fieldProps, addedProps)}
                            type={type}
                            optionsConfig={optionsConfig}
                            selectValues={selectValues}
                            path={path}
                            isRequired={isRequired}
                            isMultiselect={false}
                            getValues={getValues}
                            addedProps={{...addedProps, inModal: props.inModal}}
                            optionsFilterParameter={checkDependencies('values')}
                            isCreateMode={view === VIEWS.CREATE}
                            showLocalized={newShowLocalized}
                            language={getLanguage()}
                            isModal={isModal || fieldProps.inModal}
                        />
                        {validationError && <span className="mx-1">{validationError}</span>}
                    </>
                );
            case 'multiselect': {
                if (fieldProps?.value?.length && fieldProps?.value[fieldProps.value.length - 1]?.value === undefined) {
                    multiselectFieldProps.value = fieldProps?.value?.map(val => ({label: val, value: val}));
                }

                if (newShowLocalized === true) {
                    multiselectFieldProps.value = fieldProps?.value?.map(val => {
                        const item = selectValues?.[path]?.find(g => g.id === val.value);
                        // show english genre version for localized
                        if (emetLanguage !== 'en' && !(val?.label.split(')')[1] === '*' || val?.label.includes('('))) {
                            return {label: `${val.label} (${item.name})`, value: val.value};
                        }
                        return {label: val.label, value: val.value};
                    });

                    selectLocalizedValues = Object.assign({}, selectValues);
                    selectLocalizedValues[path] = selectLocalizedValues[path]?.map(item => {
                        const localLang = item.localizations.find(local => {
                            const isEmetLanguageObject = isObject(emetLanguage) && path === 'genres';
                            const initialEmetLanguage = isEmetLanguageObject ? emetLanguage.value : emetLanguage;
                            // path === "genres" - so that there are no errors on other similar selections

                            return local?.language === initialEmetLanguage;
                        });

                        const enName = item?.name;
                        if (localLang) {
                            item.displayName = `${localLang?.name}(${enName})`;
                        } else if (emetLanguage) item.displayName = `(${enName})*`;
                        else item.displayName = enName;
                        return item;
                    });
                    // displayName is used in dropdown for display purpose only. to send to api, use "name"
                    newOptionsConfig = {...optionsConfig, defaultLabelPath: 'name'};
                }

                return (
                    <NexusSelect
                        key={path}
                        id={generateElementIds(fieldProps, addedProps)}
                        fieldProps={{
                            formControlOptions: {
                                formControlName: path,
                                rules: getFormRules(),
                            },
                            labelProps: {
                                label,
                                shouldUpper: true,
                                stacked: shouldStackLabel,
                                isRequired,
                                columnClass: 'col-4',
                            },
                            columnClass: 'col-8',
                        }}
                        type={type}
                        getValues={getValues}
                        optionsConfig={
                            newShowLocalized === true && emetLanguage !== 'en' ? newOptionsConfig : optionsConfig
                        }
                        selectValues={
                            newShowLocalized === true && emetLanguage !== 'en' ? selectLocalizedValues : selectValues
                        }
                        path={path}
                        isRequired={isRequired}
                        isMultiselect={true}
                        addedProps={addedProps}
                        showLocalized={newShowLocalized}
                        language={getLanguage()}
                        optionsFilterParameter={checkDependencies('values')}
                        isModal={isModal || fieldProps.inModal}
                    />
                );
            }
            case 'dateRange': {
                return (
                    <div className="row align-items-center">
                        <div className="col-4">
                            <Label label={label} shouldUpper={true} stacked={false} isRequired={isRequired} />{' '}
                        </div>
                        <div className="col-8">
                            <DateTimeWithOptional
                                key={path}
                                onChange={e => {
                                    setFieldValue(path[0], e.startDate);
                                    setFieldValue(path[1], e.endDate);
                                }}
                                value={value}
                                formFields={{
                                    formControlOptions: {
                                        formControlName: path,
                                        rules: getFormRules(),
                                    },
                                    columnClass: 'col-8',
                                    value,
                                }}
                                todayResetsToMidnight={fieldProps.todayResetsToMidnight}
                                columnClass="col-8"
                                {...dateProps}
                                id={generateElementIds(fieldProps, addedProps)}
                            />
                        </div>
                    </div>
                );
            }
            case 'datetime': {
                const hasWithDrawnDate = fieldProps.name.includes('dateWithdrawn');

                const {BUSINESS_DATETIME, TIMESTAMP} = DATETIME_FIELDS;
                const isUsingTime = [BUSINESS_DATETIME, TIMESTAMP].includes(dateType);

                return value || !dateProps.isReadOnly ? (
                    <div className="row">
                        {!isOptional && (
                            <div className="col-4">
                                <Label label={label} shouldUpper={true} stacked={false} isRequired={isRequired} />
                            </div>
                        )}
                        <div className={!isOptional ? 'col-8' : 'col-12'}>
                            <DateTimeWithOptional
                                key={path}
                                onChange={e => {
                                    setFieldValue(
                                        path,
                                        e
                                            ? moment(e).format(
                                                  hasWithDrawnDate
                                                      ? TIMESTAMP_DATE_FORMAT
                                                      : isUsingTime
                                                      ? RELATIVE_DATE_FORMAT
                                                      : RELATIVE_DATE_FORMAT_WITHOUT_TIME
                                              )
                                            : e
                                    );
                                }}
                                value={value}
                                path={path}
                                name={fieldProps?.name}
                                getValues={getValues}
                                todayResetsToMidnight={fieldProps.todayResetsToMidnight}
                                formFields={{
                                    formControlOptions: {
                                        formControlName: path,
                                        rules: getFormRules(),
                                    },
                                    value,
                                }}
                                label={label}
                                isRequired={isRequired}
                                useCurrentDate={useCurrentDate}
                                isOptional={isOptional}
                                elementId={generateElementIds(fieldProps, addedProps)}
                                territoryLenght={hasWithDrawnDate && formData?.territory?.length}
                                {...dateProps}
                            />
                        </div>
                    </div>
                ) : (
                    ''
                );
            }
            case 'castCrew':
                return (
                    <CastCrew
                        key={path}
                        sectionId={generateElementIds(fieldProps, addedProps)}
                        isEditable={isEditable}
                        onChange={value => setFieldValue(path, value)}
                        {...fieldProps}
                        persons={value}
                        isEdit={true}
                        getValues={getValues}
                        setFieldValue={setFieldValue}
                        isVerticalLayout={isVerticalLayout}
                        isTitlePage={isTitlePage}
                        searchPerson={searchPerson}
                        castCrewConfig={castCrewConfig}
                        language={isVerticalLayout ? getLanguage() : 'en'}
                        setUpdate={setUpdate}
                        allData={allData}
                        forMetadata={forMetadata}
                        path={path}
                        formControlOptions={{
                            formControlName: path,
                            rules: getFormRules(),
                        }}
                    />
                );
            case 'rowDataItem':
                return (
                    <RowDataItem
                        key={path}
                        {...props}
                        formControlOptions={{
                            formControlName: path,
                            rules: getFormRules(),
                        }}
                        id={generateElementIds(fieldProps, addedProps)}
                        selectValues={selectValues}
                        setFieldValue={setFieldValue}
                        path={path}
                        data={value || []}
                    />
                );
            case 'msvIds':
                return (
                    <MsvIds
                        key={path}
                        formControlOptions={{
                            formControlName: path,
                            rules: getFormRules(),
                        }}
                        label={label}
                        id={generateElementIds(fieldProps, addedProps)}
                        selectValues={selectValues}
                        data={value || []}
                        isEdit={true}
                        onChange={value => setFieldValue(path, value)}
                        generateMsvIds={generateMsvIds}
                    />
                );
            case 'tenantData': {
                return (
                    <TextFieldWithOptional
                        key={path}
                        formControlOptions={{
                            formControlName: path,
                            rules: getFormRules(),
                        }}
                        {...addedProps}
                        labelProps={{
                            label,
                            shouldUpper: true,
                            stacked: shouldStackLabel,
                            isRequired,
                            columnClass: 'col-4',
                        }}
                        columnClass="col-8"
                        id={generateElementIds(fieldProps, addedProps)}
                        placeholder={`Enter ${label}`}
                        value={get(props, 'value')}
                    />
                );
            }
            case 'tags':
                return (
                    <div className="px-2 mx-1">
                        <TagManagement
                            key={path}
                            tags={findValue(addedProps) || []}
                            elementId={generateElementIds(fieldProps, addedProps)}
                            path={path}
                            setFieldValue={setFieldValue}
                        />
                    </div>
                );
            default:
                return;
        }
    };

    const getValueFromSelectValues = (field, value) => {
        const values = selectValues?.[field] || [];
        const option = values.find(o => o[`${field}Code`] === value);
        return option?.[`${field}Name`] || value;
    };

    const hasLocalizedValue = value => {
        return !(typeof value === 'string' && value.includes('(') && value.includes(')*'));
    };

    const getLabel = item => {
        if (typeof item === 'object' && localizationConfig) {
            if (newShowLocalized) {
                const obj = selectValues?.[props.originalPath || path]?.find(g => g.id === (item.value || item.id));
                const local = obj?.localizations?.find(g => g?.language === emetLanguage);
                if (local && emetLanguage !== 'en') {
                    return `${local.name || item.label} (${obj.name})`;
                }
            }
            return item?.label
                ? item.label
                : !emetLanguage || emetLanguage === 'en'
                ? item[localizationConfig.default]
                : item[localizationConfig.localized];
        }
        return typeof item === 'object' ? item.label : item;
    };

    const getValue = fieldProps => {
        const values = getValues(path);

        if (Array.isArray(values) && values.length) {
            const val = values;
            const filteredOptions = selectValues[fieldProps.originalPath || path]?.filter(x => val.includes(x.id));

            if (filteredOptions && filteredOptions.length) {
                const arrayValues = filteredOptions?.map(item => getLabel(item));
                if (newShowLocalized) {
                    return (
                        <div>
                            {arrayValues?.map((item, index) => (
                                <span
                                    key={index}
                                    title={!hasLocalizedValue(item) ? LOCALIZED_VALUE_NOT_DEFINED : undefined}
                                    className={!hasLocalizedValue(item) ? 'italic' : undefined}
                                >
                                    {item}
                                    {index !== arrayValues.length - 1 && ', '}
                                </span>
                            ))}
                        </div>
                    );
                }

                return filteredOptions?.map(value => getFieldValue(value, optionsConfig)).join(', ');
            }

            return <div className="nexus-c-field__placeholder">{`Enter ${label}...`}</div>;
        }

        if (/country|locale/i.test(fieldProps.name) || /language/i.test(fieldProps.name)) {
            // the section doesn't get refreshed (rights detail) when save, hence the below check
            const val = typeof values === 'object' ? values.value : values;
            const key = /country|locale/i.test(fieldProps.name) ? 'country' : 'language';
            return getValueFromSelectValues(key, val);
        }

        return getFieldValue(values);
    };

    const renderFieldViewMode = fieldProps => {
        switch (type) {
            case 'boolean':
                return (
                    <Checkbox
                        // checked={!!getValues(path)}
                        elementId={generateElementIds(fieldProps, addedProps)}
                        inputId={generateElementIds(fieldProps, addedProps)}
                        disabled
                        formControlOptions={{
                            formControlName: path,
                            rules: getFormRules(),
                        }}
                        labelProps={{
                            label,
                            shouldUpper: true,
                            stacked: false,
                            isRequired,
                        }}
                        labelPosition="left"
                        labelClassName="col-4 ms-2"
                    />
                );
            case 'dateRange':
            case 'datetime': {
                const value = getValues(path);
                const hasWithDrawnDate = fieldProps.name.includes('dateWithdrawn');
                if (value) {
                    return (
                        <div className="row mb-4">
                            <div className="col-4">
                                <Label label={label} shouldUpper={true} stacked={false} isRequired={isRequired} />
                            </div>
                            <div className="col-8">
                                <DateTimeWithOptional
                                    key={path}
                                    onChange={e => {
                                        setFieldValue(
                                            path,
                                            e ? moment(e).format(RELATIVE_DATE_FORMAT_WITHOUT_TIME) : e
                                        );
                                    }}
                                    value={value}
                                    path={path}
                                    name={fieldProps?.name}
                                    getValues={getValues}
                                    formFields={{
                                        formControlOptions: {
                                            formControlName: path,
                                            rules: getFormRules(),
                                        },
                                        value,
                                    }}
                                    label={label}
                                    isRequired={isRequired}
                                    useCurrentDate={useCurrentDate}
                                    isOptional={isOptional}
                                    elementId={generateElementIds(fieldProps, addedProps)}
                                    territoryLenght={hasWithDrawnDate && formData?.territory?.length}
                                    todayResetsToMidnight={fieldProps.todayResetsToMidnight}
                                    {...dateProps}
                                    isReadOnly
                                />
                            </div>
                        </div>
                    );
                }
                return (
                    <div className="row mb-4 mt-2">
                        <div className="col-4">
                            <Label label={label} shouldUpper={true} stacked={false} isRequired={isRequired} />
                        </div>
                        <div className="col-8">
                            <div className="nexus-c-field__placeholder" id={generateElementIds(fieldProps, addedProps)}>
                                {`Enter ${label}...`}
                            </div>
                        </div>
                    </div>
                );
            }
            case 'castCrew':
                return (
                    <CastCrew
                        persons={getValues(path)}
                        isEdit={false}
                        getValues={getValues}
                        setFieldValue={setFieldValue}
                        isVerticalLayout={isVerticalLayout}
                        forMetadata={forMetadata}
                        isTitlePage={isTitlePage}
                        searchPerson={searchPerson}
                        castCrewConfig={castCrewConfig}
                        path={path}
                        // isVerticalLayout is used in EMET section, hence used to distinguish b/w core and emet section
                        language={isVerticalLayout ? get(formData, 'editorialMetadata.language', 'en') : 'en'}
                        sectionId={generateElementIds(fieldProps, addedProps)}
                    />
                );
            case 'tags':
                return (
                    <TagManagement
                        tags={findValue(addedProps) || []}
                        elementId={generateElementIds(fieldProps, addedProps)}
                        shouldUpper={true}
                        path={path}
                        setFieldValue={setFieldValue}
                    />
                );
            case 'rowDataItem':
                return (
                    <RowDataItem
                        {...props}
                        {...fieldProps}
                        selectValues={selectValues}
                        data={getValues(path) || []}
                        canDelete={false}
                        canAdd={false}
                        id={generateElementIds(fieldProps, addedProps)}
                    />
                );
            case 'msvIds':
                return (
                    <MsvIds
                        selectValues={selectValues}
                        data={getValues(path) || []}
                        isEdit={false}
                        id={generateElementIds(fieldProps, addedProps)}
                    />
                );
            case 'link': {
                const url = createUrl(linkConfig, initialData);
                const body = getValues(path) ? (
                    <div>
                        <span id={generateElementIds(fieldProps, addedProps)}>{getValue(fieldProps)}</span>
                    </div>
                ) : (
                    <div id={generateElementIds(fieldProps, addedProps)} className="nexus-c-field__placeholder">
                        {`Enter ${label}...`}
                    </div>
                );

                return (
                    <div className="row">
                        <div className="col-4">
                            <Label label={label} shouldUpper={true} stacked={false} isRequired={isRequired} />
                        </div>
                        <div className="col-8">
                            {url.includes('http') ? <a href={url}>{body}</a> : <Link to={`./../${url}`}>{body}</Link>}
                        </div>
                    </div>
                );
            }
            case 'tenantData': {
                return get(props, 'value') ? (
                    <div className="row mb-4 mt-2">
                        <div className="col-4">
                            <Label label={label} shouldUpper={true} stacked={false} isRequired={isRequired} />
                        </div>
                        <div className="col-8">
                            <span>{get(props, 'value')}</span>
                        </div>
                    </div>
                ) : (
                    <div className="row mb-4 mt-2">
                        <div className="col-4">
                            <Label label={label} shouldUpper={true} stacked={false} isRequired={isRequired} />
                        </div>
                        <div className="col-8">
                            <div className="nexus-c-field__placeholder" id={generateElementIds(fieldProps, addedProps)}>
                                {`Enter ${label}...`}
                            </div>
                        </div>
                    </div>
                );
            }
            default:
                return getValues(path) ? (
                    <div className="row mb-4 mt-2">
                        <div className="col-4">
                            <Label label={label} shouldUpper={true} stacked={false} isRequired={isRequired} />
                        </div>
                        <div className={`col-8 ${shouldUpperCase ? 'text-uppercase' : ''}`}>
                            <span
                                dir={hebrew.test(getValue(fieldProps)) ? 'rtl' : 'ltr'}
                                id={generateElementIds(fieldProps, addedProps)}
                            >
                                {getValue(fieldProps)}
                            </span>
                        </div>
                        {validationError && <span className="mx-1">{validationError}</span>}
                    </div>
                ) : (
                    <div className="row mb-4 mt-2">
                        <div className="col-4">
                            <Label label={label} shouldUpper={true} stacked={false} isRequired={isRequired} />
                        </div>
                        <div className={`col-8 ${shouldUpperCase ? 'text-uppercase' : ''}`}>
                            <div className="nexus-c-field__placeholder" id={generateElementIds(fieldProps, addedProps)}>
                                {`Enter ${label}...`}
                            </div>
                        </div>
                        {validationError && <span className="mx-1">{validationError}</span>}
                    </div>
                );
        }
    };

    return (
        <ErrorBoundary>
            <div className={`nexus-c-field${isHighlighted ? ' nexus-c-field--highlighted' : ''}`}>
                <div className={stackLabel ? 'stack-label w-100' : 'display-contents'}>
                    <div className="row w-100 align-items-center">
                        <div className="col-12">
                            <div
                                className={`nexus-c-field__value-section ${
                                    validationError ? 'nexus-c-field--error pb-2' : ''
                                }`}
                            >
                                <div className="nexus-c-field__value">
                                    {!getIsReadOnly() && (view === VIEWS.EDIT || view === VIEWS.CREATE)
                                        ? renderFieldEditMode(props)
                                        : renderFieldViewMode(props)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    );
};

NexusField.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    view: PropTypes.string,
    tooltip: PropTypes.string,
    formData: PropTypes.object,
    dependencies: PropTypes.array,
    isReadOnly: PropTypes.bool,
    isReadOnlyInEdit: PropTypes.bool,
    isRequired: PropTypes.bool,
    isClearable: PropTypes.bool,
    validationError: PropTypes.string,
    validation: PropTypes.array,
    optionsConfig: PropTypes.object,
    selectValues: PropTypes.object,
    path: PropTypes.any,
    dateType: PropTypes.string,
    labels: PropTypes.array,
    label: PropTypes.string,
    isOptional: PropTypes.bool,
    setFieldValue: PropTypes.func,
    // eslint-disable-next-line react/boolean-prop-naming
    useCurrentDate: PropTypes.bool,
    isHighlighted: PropTypes.bool,
    isTitlePage: PropTypes.bool,
    getCurrentValues: PropTypes.func,
    isReturningTime: PropTypes.bool,
    config: PropTypes.array,
    isEditable: PropTypes.bool,
    isGridLayout: PropTypes.bool,
    isVerticalLayout: PropTypes.bool,
    searchPerson: PropTypes.func,
    generateMsvIds: PropTypes.func,
    setDisableSubmit: PropTypes.func,
    initialData: PropTypes.object,
    linkConfig: PropTypes.object,
    maxLength: PropTypes.number,
    rows: PropTypes.number,
    cols: PropTypes.number,
    castCrewConfig: PropTypes.object,
    isRequiredVZ: PropTypes.bool,
    oneIsRequiredVZ: PropTypes.bool,
    showLocalized: PropTypes.bool,
    localizationConfig: PropTypes.object,
    getValues: PropTypes.func,
    setUpdatedValues: PropTypes.func,
    setUpdate: PropTypes.func,
    allData: PropTypes.object,
    forMetadata: PropTypes.bool,
    shouldUpperCase: PropTypes.bool,
    stackLabel: PropTypes.bool,
    inModal: PropTypes.bool,
    sectionID: PropTypes.string,
    shouldStackLabel: PropTypes.bool,
    pathName: PropTypes.string,
    originalPath: PropTypes.string,
    triggerFormValidation: PropTypes.func,
    isModal: PropTypes.bool,
};

NexusField.defaultProps = {
    isEditable: true,
    view: VIEWS.VIEW,
    tooltip: null,
    formData: {},
    dependencies: [],
    isReadOnly: false,
    isClearable: false,
    isReadOnlyInEdit: false,
    isRequired: false,
    validationError: '',
    validation: [],
    optionsConfig: {},
    selectValues: {},
    path: null,
    dateType: '',
    labels: [],
    label: '',
    isOptional: false,
    setFieldValue: null,
    useCurrentDate: false,
    isHighlighted: false,
    getCurrentValues: () => null,
    isReturningTime: true,
    isTitlePage: false,
    config: [],
    isGridLayout: false,
    isVerticalLayout: false,
    searchPerson: undefined,
    generateMsvIds: undefined,
    setDisableSubmit: undefined,
    initialData: {},
    linkConfig: {},
    castCrewConfig: {},
    maxLength: undefined,
    rows: undefined,
    cols: undefined,
    isRequiredVZ: false,
    oneIsRequiredVZ: false,
    showLocalized: false,
    localizationConfig: undefined,
    getValues: () => null,
    setUpdatedValues: () => null,
    setUpdate: () => null,
    allData: {},
    forMetadata: false,
    shouldUpperCase: false,
    stackLabel: false,
    inModal: false,
    sectionID: '',
    shouldStackLabel: false,
    pathName: '',
    originalPath: '',
    triggerFormValidation: () => null,
    isModal: false,
};

export default NexusField;
