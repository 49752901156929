import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Dialog} from '@portal/portal-components';
import {useDispatch} from 'react-redux';
import {addToast} from '../../../../../../toast/NexusToastNotificationActions';
import {generateValidHtmlCamelCaseId} from '../../../../../../utils/utils';
import AddRowDataItem from './AddRowDataItem';
import './RowDataItem.scss';

const RowDataItem = ({
    selectValues,
    data,
    firstColLbl,
    firstColKey,
    secondColLbl,
    secondColKey,
    thirdColLbl,
    thirdColKey,
    canAdd,
    canDelete,
    onChange,
    setFieldValue,
    path,
    ...props
}) => {
    const dispatch = useDispatch();
    const {addNewModalOptions} = props;

    const [entries, setEntries] = useState([]);
    const [displayModal, setDisplayModal] = useState(false);

    useEffect(() => {
        setEntries(data);
    }, [data]);

    const openAddRowDataItemModal = e => {
        e.preventDefault();
        setDisplayModal(true);
    };

    /**
     * Loop through the entries of the fields, and check if {selectValues} has a property named
     * with the value of `path` - example: externalSystem path
     * @returns {boolean} - whether to show button or not
     */
    const showCreateButton = () => {
        let canShowElement = true;
        props.addNewModalOptions.fields?.forEach(f => {
            f.sections.forEach(s => {
                Object.entries(s.fields).forEach(entry => {
                    // if this is a Select element and if it's path exists in selectValues
                    if (entry[1].type === 'select' && !selectValues?.[entry[1].path]) {
                        // flag this as do not show
                        canShowElement = false;
                    }
                });
            });
        });
        return canShowElement;
    };

    const onAddRowDataItem = async values => {
        const uniqueByFields = props.addNewModalOptions.uniqueByFields;
        let entryExists = true;

        uniqueByFields.forEach(field => {
            const entryFound = entries.find(item => values[field] === (item.id || item[field]));
            entryExists &&= !!entryFound;
        });

        if (entryExists) {
            const errorToast = {
                severity: 'error',
                detail: props.addNewModalOptions.duplicationErrorMessage,
            };
            dispatch(addToast(errorToast));
        } else {
            let ddls = [];
            let newEntry = {};
            // Dynamic transformation for select elements.
            // Based on the schema field outputMap passed in for selects.
            //     outputMap: {
            //         <writeKey> : <pickFromKey>
            //         licensor: 'name',
            //     }
            // Above example will pick the value from key name and will put it in key licensor in the output.

            props.addNewModalOptions.fields.forEach(f => {
                f.sections.forEach(s => {
                    ddls = [...ddls, ...Object.entries(s.fields).filter(entry => entry[1].type === 'select')];

                    Object.entries(s.fields).forEach(entry => {
                        if (entry[1].type !== 'select') {
                            newEntry = {...newEntry, [entry[0]]: values[entry[0]]};
                        }
                    });
                });
            });

            ddls.forEach(d => {
                if (d[1].outputMap) {
                    const selectedItem = selectValues[props.addNewModalOptions.valuesPath].find(
                        x => x.id === values[d[0]]
                    );
                    Object.entries(d[1].outputMap).forEach(outMap => {
                        newEntry = {...newEntry, [outMap[0]]: selectedItem[outMap[1]]};
                    });
                } else {
                    newEntry = {...newEntry, [d[0]]: values[d[0]]};
                }
            });

            const updatedEntries = [...entries];
            updatedEntries.push(newEntry);
            setEntries(updatedEntries);
            setFieldValue(path, updatedEntries);
            setDisplayModal(false);
        }
    };

    const removeRowDataItem = index => {
        const updatedEntries = [...entries];
        updatedEntries.splice(index, 1);
        setEntries(updatedEntries);
        onChange([...updatedEntries]);
        setFieldValue(path, updatedEntries);
    };

    return (
        <div>
            {entries.map((entry, index) => {
                return (
                    <div key={index} className="nexus-c-row-data-item">
                        {firstColLbl && entry[firstColKey] && (
                            <div className="nexus-c-row-data-item-col__1">
                                <span>{firstColLbl}</span>
                                <span>{entry[firstColKey]}</span>
                            </div>
                        )}
                        {secondColLbl && entry[secondColKey] && (
                            <div className="nexus-c-row-data-item-col__2">
                                <span>{secondColLbl}</span>
                                <span>{entry[secondColKey]}</span>
                            </div>
                        )}
                        <div className="nexus-c-row-data-item-col__3">
                            {thirdColLbl && entry[thirdColKey] && (
                                <>
                                    <span>{thirdColLbl}</span>
                                    <span>{entry[thirdColKey]}</span>
                                </>
                            )}
                        </div>
                        {canDelete ? (
                            <Button
                                elementId="btnRemoveRowDataItem"
                                icon="po po-close"
                                className="p-button-text"
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    removeRowDataItem(index);
                                }}
                            />
                        ) : undefined}
                    </div>
                );
            })}

            {canAdd && showCreateButton() && (
                <div>
                    <Button
                        elementId={generateValidHtmlCamelCaseId(`btnOpenRowDataModal.${firstColKey}`)}
                        className="p-button-outlined p-button-secondary"
                        label={addNewModalOptions.triggerBtnLabel}
                        onClick={openAddRowDataItemModal}
                    />
                </div>
            )}
            <Dialog
                elementId="nexusModal_addRowDataItem"
                header={<div>{addNewModalOptions.header}</div>}
                visible={displayModal}
                style={{width: '35vw'}}
                onHide={() => null}
                closeOnEscape={false}
                closable={false}
                onSubmit={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <AddRowDataItem
                    onAddRowDataItem={onAddRowDataItem}
                    schema={addNewModalOptions}
                    closeModal={() => setDisplayModal(false)}
                    selectValues={selectValues}
                />
            </Dialog>
        </div>
    );
};

RowDataItem.propTypes = {
    selectValues: PropTypes.object,
    firstColLbl: PropTypes.string,
    firstColKey: PropTypes.any,
    secondColLbl: PropTypes.string,
    secondColKey: PropTypes.any,
    thirdColLbl: PropTypes.string,
    thirdColKey: PropTypes.any,
    path: PropTypes.string,
    data: PropTypes.array.isRequired,
    canAdd: PropTypes.bool,
    canDelete: PropTypes.bool,
    addNewModalOptions: PropTypes.any,
    onChange: PropTypes.func,
    setFieldValue: PropTypes.func,
};

RowDataItem.defaultProps = {
    selectValues: {},
    firstColLbl: '',
    firstColKey: '',
    secondColLbl: '',
    secondColKey: '',
    thirdColLbl: '',
    thirdColKey: '',
    path: '',
    canAdd: false,
    canDelete: false,
    addNewModalOptions: undefined,
    onChange: () => null,
    setFieldValue: () => null,
};

export default RowDataItem;
