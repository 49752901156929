import * as actionTypes from './titleMetadataActionTypes';

export const clearTitleMetadataFilter = () => ({
    type: actionTypes.CLEAR_TITLE_FILTER,
});

export const storeTitleContentTypes = payload => ({
    type: actionTypes.SET_TITLE_CONTENT_TYPES,
    payload,
});

export const setExternalIdValues = payload => ({
    type: actionTypes.SET_EXTERNAL_ID_DROPDOWN,
    payload,
});

export const setSelectedTitles = payload => ({
    type: actionTypes.SET_SELECTED_TITLES,
    payload,
});

export const setVersionTypes = payload => ({
    type: actionTypes.SET_VERSION_TYPES,
    payload,
});
