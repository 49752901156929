import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button} from '@portal/portal-components';
import classnames from 'classnames';
import moment from 'moment';
import {generateValidHtmlCamelCaseId} from '../../../../utils/utils';
import './SideTabs.scss';

const SideTabs = ({data, currentTab, setCurrentTab, path, onChange, subTabs, isRemoved, clearIsRemoved}) => {
    useEffect(() => {
        if (isRemoved) {
            setCurrentTab({
                tabIndex: 0,
                subTabIndex: 0,
            });
            clearIsRemoved();
        }
    }, [isRemoved]);

    const handleTabChanged = (e, selectedRecords, tabIndex, subTabIndex = 0) => {
        e.preventDefault();
        const prevTab = currentTab.tabIndex;
        const prevSubTab = currentTab.subTabIndex;
        setCurrentTab({
            tabIndex,
            subTabIndex,
        });
        const indexes = {
            prevTab,
            prevSubTab,
            currentTab: tabIndex,
            currentSubTab: subTabIndex,
        };

        onChange(selectedRecords[subTabIndex], indexes);
    };

    const checkSubTabValues = obj => {
        let toRender = false;
        subTabs.forEach(key => {
            if (obj?.[key]) {
                toRender = true;
            }
        });
        return toRender;
    };

    const getSubTabLabel = obj => {
        let value = '';
        subTabs.forEach(key => {
            if (obj[key]) value = value.concat(' ', obj[key]);
        });
        return value;
    };

    const renderSideSubTabs = (data, key, index) => {
        const toReturn = [];
        const masterTitle = data[key].find(e =>
            e?.tenantData?.complexProperties?.find(e =>
                e?.simpleProperties.find(e => e.name === 'hasGeneratedChildren')
            )
        );
        data[key].forEach((obj, subIndex) => {
            const duration = moment.duration(moment.utc(masterTitle?.updatedAt).diff(moment.utc(obj?.updatedAt)));
            const secs = Math.abs(duration.asSeconds());
            const isDecorated = secs < 5;
            const isRatings = path === 'ratings';
            const tenantDataAttributes = obj?.tenantData?.complexProperties?.find(e => e.name === 'auto-decorate');
            const isGeneratedValue = tenantDataAttributes?.simpleProperties?.find(e => e.name === 'isGenerated')?.value;

            if (checkSubTabValues(obj) && !obj.isDeleted) {
                toReturn.push(
                    <div
                        key={`${key}[${subIndex}]`}
                        className={classnames('nexus-c-side-tabs__subtab-container', {
                            'nexus-c-side-tabs__subtab-container--active':
                                currentTab.tabIndex === index && currentTab.subTabIndex === subIndex,
                            'nexus-c-side-tabs__subtab-container--open': currentTab.tabIndex === index,
                        })}
                    >
                        {isDecorated && isGeneratedValue && !isRatings && (
                            // <StatusLink className="tablinks__status-link" />
                            <i className="po po-linked" />
                        )}
                        <Button
                            elementId={generateValidHtmlCamelCaseId(`sideTabBtn.${getSubTabLabel(obj)}`)}
                            label={getSubTabLabel(obj)}
                            onClick={e => handleTabChanged(e, data[key], index, subIndex)}
                            className="p-button-text w-100"
                        />
                    </div>
                );
            }
        });
        return toReturn;
    };

    const renderSideTabs = () => {
        return Object.keys(data).map((key, index) => {
            let toReturn = [];
            toReturn.push(
                <div key={key} className="nexus-c-side-tabs__tab-container">
                    <Button
                        elementId={generateValidHtmlCamelCaseId(`sideTabBtn.${key}`)}
                        label={key}
                        onClick={e => handleTabChanged(e, data[key], index)}
                        className="nexus-c-side-tabs__tab-button p-button-text w-100"
                        icon="po po-expand-section"
                        iconPos="right"
                    />
                </div>
            );
            const subTabsArray = renderSideSubTabs(data, key, index);
            if (data[key].length !== subTabsArray.length) {
                const missedTabIndex = data[key].findIndex(e => !checkSubTabValues(e));
                toReturn.splice(
                    0,
                    1,
                    <div
                        key={key}
                        className={classnames('nexus-c-side-tabs__tab-container', {
                            'nexus-c-side-tabs__tab-container--active':
                                currentTab.tabIndex === index && currentTab.subTabIndex === missedTabIndex,
                        })}
                    >
                        <Button
                            elementId={generateValidHtmlCamelCaseId(`sideTabSubBtn.${key}`)}
                            label={key}
                            onClick={e => handleTabChanged(e, data[key], index, missedTabIndex)}
                            className="nexus-c-side-tabs__tab-button p-button-text w-100"
                            icon="po po-expand-section"
                            iconPos="right"
                        />
                    </div>
                );
            }
            if (subTabsArray.length === 0) {
                toReturn.splice(
                    0,
                    1,
                    <div
                        key={key}
                        className={classnames('nexus-c-side-tabs__tab-container', {
                            'nexus-c-side-tabs__tab-container--active': currentTab.tabIndex === index,
                        })}
                    >
                        <Button
                            elementId={generateValidHtmlCamelCaseId(`sideTabSubBtn.${key}`)}
                            label={key}
                            onClick={e => handleTabChanged(e, data[key], index)}
                            className="p-button-text w-100"
                        />
                    </div>
                );
            } else {
                toReturn = toReturn.concat(subTabsArray);
            }
            return toReturn;
        });
    };

    return <div className="nexus-c-side-tabs">{renderSideTabs()}</div>;
};

SideTabs.propTypes = {
    data: PropTypes.object,
    currentTab: PropTypes.object,
    setCurrentTab: PropTypes.func,
    path: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    subTabs: PropTypes.array,
    isRemoved: PropTypes.bool,
    clearIsRemoved: PropTypes.func.isRequired,
};

SideTabs.defaultProps = {
    data: {},
    currentTab: {},
    setCurrentTab: () => null,
    subTabs: [],
    isRemoved: false,
};

export default SideTabs;
