import {keycloak} from '@portal/portal-auth';
import handleError from './handleError';
import handleResponse from './handleResponse';
import {getMaxApiRetries} from '../config';

const DEFAULT_TIMEOUT = 60000;
const RETRY_DELAY = 500; // in milliseconds

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const fetchAPI = async (
    url,
    options = {},
    abortAfter = DEFAULT_TIMEOUT,
    fetchHeaders = false,
    maxRetries = getMaxApiRetries()
) => {
    for (let i = 0; i <= maxRetries; i++) {
        let controller = new AbortController();
        const {signal} = controller;
        const {token = ''} = keycloak || {};

        const allOptions = {
            signal,
            method: 'get',
            ...options,
            headers: {
                ...(!options.file ? {'Content-type': 'application/json'} : {}),
                ...(token ? {Authorization: `Bearer ${token}`} : {}),
                ...options.headers,
            },
        };

        // abort request
        const timeoutId = setTimeout(() => {
            if (controller && abortAfter) {
                controller.abort();
            }
        }, abortAfter);

        try {
            const response = await fetch(url, allOptions);
            const parsedResponse = await handleResponse(response, fetchHeaders);

            if (response.ok) {
                return parsedResponse;
            }
        } catch (error) {
            /**
             * if the fetch request throws an error due to a timeout (AbortError), and the maximum number of retries hasn't been reached,
             * it will delay and then retry the request. For all other types of errors, including 400 and 500 series HTTP status codes,
             * it will not retry and instead will immediately throw the error using your handleError function.
             */
            if (error.name === 'AbortError' && i < maxRetries) {
                await delay(RETRY_DELAY);
            } else {
                throw handleError(error, options);
            }
        } finally {
            controller = null;
            clearTimeout(timeoutId);
        }
    }
};

/**
 * nexusFetch
 *
 * @param url
 * @param options={...fetchOptions, params, ...rest}
 * @param abortAfter=DEFAULT_TIMEOUT
 * @param fetchHeaders: boolean flag that controls whether response headers will be returned along with response body
 * @param maxRetries: how many times to retry the API call after the initial failure (initial API call + maxRetries)
 * @returns Promise (result fetch api)
 */
export const nexusFetch = (url, options = {}, abortAfter = DEFAULT_TIMEOUT, fetchHeaders, maxRetries) => {
    const {params, ...rest} = options;
    let clonedUrl = url;
    if (params && typeof params === 'string') {
        clonedUrl += `${url.indexOf('?') === -1 ? '?' : '&'}${params}`;
    }

    return fetchAPI(clonedUrl, rest, abortAfter, fetchHeaders, maxRetries);
};
