import {getAuthConfig, getEnv, getRealm} from '../common/utils/config';

/**
 * Calculates the dynamic url of the external application using env
 * @param menuOption {object} - in menuOptions.json
 * @returns {string} - final url to redirect
 */
export const calculateExternalUrl = menuOption => {
    const {url = '', urls = []} = menuOption;
    const currentEnv = getEnv();
    const realm = getRealm();

    if (url) {
        return url.replace('{env}', currentEnv).replace('{realm}', realm);
    }

    if (urls.length) {
        const {realm} = getAuthConfig();
        const env2 = menuOption?.urls.find(list => list.key === currentEnv)?.url;
        // TODO: WA for pen and prod as they don't have instances (of config) for now
        return `${env2 || 'https://d3ksjc9aihv2ke.cloudfront.net/'}${realm}${menuOption.page}`;
    }
};

/**
 * Used for metavu redirections
 * @param path - the metavu path to redirect to
 * @returns {string} - calculates the env and adds the path to redirect to
 */
export const calculateTitlesRedirectRoute = (path) => {
    const currentEnv = getEnv();
    const realm = getRealm();
    const titlesBaseUrl =  "https://metavu{env}.vubiquity.com/{realm}/metadata";

    if (path) {
        return titlesBaseUrl.replace('{env}', currentEnv).replace('{realm}', realm) + path;
    }
}
