import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Calendar} from '@portal/portal-components';
import classnames from 'classnames';
import moment from 'moment';
import {useIntl} from 'react-intl';
import {getDateFormatBasedOnLocale, parseSimulcast} from '../../../utils/date-time/DateTimeUtils';
import {RELATIVE_DATE_FORMAT, SIMULCAST_DATE_FORMAT, RELATIVE_DATE_FORMAT_WITHOUT_TIME} from '../constants';
import './NexusDatePicker.scss';

const NexusDatePicker = ({
    id,
    elementId,
    isReadOnly,
    isTimestamp, // If set, value includes milliseconds and return value is in ISO format
    onChange,
    onConfirm,
    value,
    error,
    label,
    isLabelHidden, // TODO: Remove when RightDetails gets refactored/redesigned
    isReturningTime,
    isClearable,
    showActions,
    isRequired,
    ...restProps
}) => {
    const [, setDate] = useState(value || '');
    const [newDate, setNewDate] = useState(value ? moment(value).toDate() : null);
    const [isSimulcast, setIsSimulcast] = useState(false);

    useEffect(() => {
        setDate(value || '');
        setNewDate(value ? moment(value).toDate() : null);
    }, [value]);

    // Due to requirements, we check if the provided value is "zoned" and set isSimulcast accordingly
    useEffect(() => {
        typeof value === 'string' && setIsSimulcast(value.endsWith('Z'));
    }, []);

    // Get locale provided by intl
    const intl = useIntl();
    const {locale = 'en-US'} = intl || {};

    // Create date placeholder based on locale
    const dateFormat = `${getDateFormatBasedOnLocale(locale)}`;

    const RELATIVE_FORMAT = isReturningTime ? RELATIVE_DATE_FORMAT : RELATIVE_DATE_FORMAT_WITHOUT_TIME;

    const onDateChange = date => {
        if (date && date.value) {
            const formattedDate = isTimestamp
                ? moment(date.value).utc(true).toISOString()
                : moment(date.value).format(isSimulcast ? SIMULCAST_DATE_FORMAT : RELATIVE_FORMAT);
            // onConfirm will handle changes
            onChange(formattedDate);
            setDate(formattedDate);
            setNewDate(moment(date.value).toDate());
        } else {
            setDate('');
            setNewDate(null);
            onChange('');
        }
    };

    const DatePickerComponent = isReadOnly => {
        return (
            <div className="nexus_c_date_picker_filter">
                {!isLabelHidden && label && (
                    <label htmlFor={id} className={classnames(isRequired && 'required')}>
                        {label}
                    </label>
                )}
                {isReadOnly ? (
                    parseSimulcast(value, dateFormat, false)
                ) : (
                    <div className="nexus-c-date-picker__date-clear-wrapper" id={elementId}>
                        <Calendar
                            elementId={id}
                            value={newDate}
                            onChange={onDateChange}
                            placeholder={dateFormat}
                            showTime={false}
                            columnClass="col-12"
                            showIcon
                            icon="po po-calendar"
                            readOnlyInput={isReadOnly}
                            showButtonBar={showActions}
                            onClearButtonClick={e => onDateChange('')}
                            {...restProps}
                        />
                    </div>
                )}
                {error && (
                    <div className="d-flex align-items-center gap-1" style={{color: '#DE350B', fontSize: '13px'}}>
                        <i className="po po-warning" />
                        <span>{error}</span>
                    </div>
                )}
            </div>
        );
    };

    return DatePickerComponent(isReadOnly);
};

NexusDatePicker.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string,
    isReadOnly: PropTypes.bool,
    isTimestamp: PropTypes.bool,
    isLabelHidden: PropTypes.bool,
    isReturningTime: PropTypes.bool,
    onConfirm: PropTypes.func,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    isClearable: PropTypes.bool,
    isRequired: PropTypes.bool,
    elementId: PropTypes.string,
    showActions: PropTypes.bool,
};

NexusDatePicker.defaultProps = {
    label: '',
    value: '',
    error: '',
    isReadOnly: false,
    isTimestamp: false,
    isLabelHidden: false,
    isReturningTime: false,
    onConfirm: () => null,
    onChange: () => null,
    isClearable: false,
    isRequired: false,
    elementId: '',
    showActions: true,
};

export default NexusDatePicker;
