import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Calendar} from '@portal/portal-components';
import moment from 'moment';
import {
    SIMULCAST_DATE_FORMAT,
    RELATIVE_DATE_FORMAT,
    DISPLAY_DATE_TIME_FORMAT,
    DISPLAY_DATE_TIME_FORMAT_PLACEHOLDER,
} from '../constants';

const NexusSimpleDateTimePicker = ({
    label,
    id,
    value,
    defaultValue,
    onChange,
    error,
    isSimulcast,
    isTimestamp,
    isClearable,
    isClearableOnly,
    todayResetsToMidnight,
    ...restProps
}) => {
    const [date, setDate] = useState(value || '');
    const [newDate, setNewDate] = useState(value ? moment(value).toDate() : undefined);

    useEffect(() => setStrippedDate(date), [isSimulcast]);
    useEffect(() => setStrippedDate(value), [value]);
    const setStrippedDate = value => {
        if (!value) {
            setDate('');
            setNewDate(undefined);
            return;
        }
        // Removing the 'Z' at the end if it exists, because otherwise you always
        // get local date in preview, but requirements let the user choose
        // whether they want to use UTC or Relative
        const strippedValue = value.toString().endsWith('Z') ? value.slice(0, -1) : value;
        const newDate = moment(strippedValue).format(DISPLAY_DATE_TIME_FORMAT);
        setDate(newDate);
        setNewDate(moment(value).toDate());
    };

    const convertToRequiredFormat = date => {
        if (!date) {
            setDate('');
            setNewDate(undefined);
            return;
        }
        const dateWithStrippedTimezone = moment(date.value).format(DISPLAY_DATE_TIME_FORMAT);

        setDate(dateWithStrippedTimezone);
        setNewDate(moment(dateWithStrippedTimezone).toDate());

        return isTimestamp
            ? moment(dateWithStrippedTimezone)
            : moment(dateWithStrippedTimezone).format(isSimulcast ? SIMULCAST_DATE_FORMAT : RELATIVE_DATE_FORMAT);
    };

    const onDateChange = date => {
        if (date && date.value) {
            onChange(convertToRequiredFormat(date));
        }
    };

    return (
        <>
            {label && (
                <label
                    // TODO: To be fixed
                    // This id manipulation is necessary as per AtlasKit docs
                    // https://atlaskit.atlassian.com/packages/core/datetime-picker
                    htmlFor={`react-select-${id}--input`}
                >
                    {label}
                </label>
            )}
            <div>
                <div className="nexus-c-date-picker__date-clear-wrapper">
                    <Calendar
                        elementId={id}
                        value={newDate}
                        onChange={onDateChange}
                        placeholder={DISPLAY_DATE_TIME_FORMAT_PLACEHOLDER}
                        isDisabled={isClearableOnly}
                        columnClass="col-12"
                        showIcon
                        showButtonBar
                        icon="po po-calendar"
                        showTime={true}
                        stepMinute={30}
                        viewDate={defaultValue}
                        hourFormat="12"
                        onTodayButtonClick={e => {
                            e.preventDefault();
                            const date = new Date();
                            const todaysDate = {
                                value: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0),
                            };
                            todayResetsToMidnight && onChange(convertToRequiredFormat(todaysDate));
                        }}
                        {...restProps}
                    />
                </div>
                {error && (
                    <div className="d-flex align-items-center gap-1" style={{color: '#DE350B', fontSize: '13px'}}>
                        <i className="po po-warning" />
                        <span>{error}</span>
                    </div>
                )}
            </div>
        </>
    );
};

NexusSimpleDateTimePicker.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    error: PropTypes.string,
    isSimulcast: PropTypes.bool,
    isTimestamp: PropTypes.bool,
    isClearable: PropTypes.bool,
    isClearableOnly: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    todayResetsToMidnight: PropTypes.bool,
};

NexusSimpleDateTimePicker.defaultProps = {
    label: '',
    value: '',
    defaultValue: '',
    error: '',
    isSimulcast: true,
    isTimestamp: false,
    isClearable: false,
    isClearableOnly: false,
    todayResetsToMidnight: false,
};

export default NexusSimpleDateTimePicker;
