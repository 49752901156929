import React from 'react';
import {Outlet} from 'react-router-dom';
import {getEnv, getRealm} from '../../common/utils/config';
import './Welcome.scss';

const Welcome = () => {
    const currentEnv = getEnv();
    const realm = getRealm();
    const url = `mediasuite${currentEnv}.vubiquity.com/${realm}`;

    return (
        <div className="nexus-c-welcome text-center">
            <div className="col">
                <h1>Nexus Portal</h1>
                <h4>We’re transitioning from PortalApp to our new and improved MediaSuite.</h4>
                <h4>
                    Please start using <a href={`https://${url}`}>{url}</a>
                </h4>
            </div>
            <Outlet />
        </div>
    );
};

export default Welcome;
