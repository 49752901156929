import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Dropdown} from '@portal/portal-components';
import moment from 'moment';
import {useIntl} from 'react-intl';
import {isUtc} from '../../../utils/date-time/DateTimeUtils';
import NexusSimpleDateTimePicker from '../nexus-simple-date-time-picker/NexusSimpleDateTimePicker';
import {getDateFormat, getDisplayDate} from '../utils';
import {
    RELATIVE_DATE_FORMAT,
    RELATIVE_TIME_LABEL,
    SIMULCAST_DATE_FORMAT,
    SIMULCAST_TIME_LABEL,
    TIMESTAMP_DATE_FORMAT,
} from '../constants';
import './NexusDateTimePicker.scss';

const NexusDateTimePicker = ({
    id,
    elementId,
    isReadOnly,
    isViewModeDisabled, // show atlaskit component instead of view mode
    isTimestamp, // If set, value includes milliseconds and return value is in ISO format
    onChange,
    onConfirm,
    value,
    label,
    isLabelHidden, // TODO: Remove when RightDetails gets refactored/redesigned
    isClearableOnly,
    defaultValue,
    todayResetsToMidnight,
    ...restProps
}) => {
    const [date, setDate] = useState(value);
    const [firstRun, setFirstRun] = useState(true);
    const [isSimulcast, setIsSimulcast] = useState(isUtc(value || ''));

    // Get locale provided by intl
    const intl = useIntl();
    const {locale = 'en-US'} = intl || {};
    const dateFormat = getDateFormat(locale, isTimestamp, isSimulcast);
    const displayDate = getDisplayDate(
        value || (restProps?.path ? restProps?.getValues?.(restProps.path) : ''),
        locale,
        isTimestamp,
        isSimulcast
    );

    useEffect(() => {
        date &&
            setDate(
                moment
                    .utc(date)
                    .format(
                        isTimestamp ? TIMESTAMP_DATE_FORMAT : isSimulcast ? SIMULCAST_DATE_FORMAT : RELATIVE_DATE_FORMAT
                    )
            );
    }, [isSimulcast, isTimestamp]);

    const onFormatChange = isSimulcast =>
        setDate(
            moment(date).format(
                isTimestamp ? TIMESTAMP_DATE_FORMAT : isSimulcast ? SIMULCAST_DATE_FORMAT : RELATIVE_DATE_FORMAT
            )
        );

    useEffect(() => {
        !firstRun && onChange(date);
        setFirstRun(false);
    }, [date]);

    const DatePicker = isReadOnly => {
        return (
            <>
                {!isLabelHidden && label && <div className="nexus-c-date-time-picker__label">{label}</div>}
                {isReadOnly && !isViewModeDisabled ? (
                    displayDate
                ) : (
                    <div className="nexus-c-date-time-picker col-12">
                        <div className={`nexus-c-date-time-picker__date-time ${!isTimestamp ? 'col-8' : 'col-12'} `}>
                            <NexusSimpleDateTimePicker
                                id={id}
                                onChange={e => {
                                    setDate(e);
                                }}
                                value={value || ''}
                                isSimulcast={isSimulcast}
                                isTimestamp={isTimestamp}
                                defaultValue={
                                    defaultValue || (isSimulcast ? value : moment(value).local().format(dateFormat))
                                }
                                isClearableOnly={isClearableOnly}
                                todayResetsToMidnight={todayResetsToMidnight}
                                {...restProps}
                            />
                        </div>
                        {!isTimestamp && ( // Timestamps are always UTC, no need for this option
                            <div className="nexus-c-date-time-picker__type-select col-4">
                                <Dropdown
                                    value={!!isSimulcast}
                                    columnClass="col-12"
                                    elementId="ddlDateFormat"
                                    options={[
                                        {label: RELATIVE_TIME_LABEL, value: false},
                                        {label: SIMULCAST_TIME_LABEL, value: true},
                                    ]}
                                    onChange={e => {
                                        setIsSimulcast(e.value);
                                        onFormatChange(e.value);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}
            </>
        );
    };

    return <div id={elementId}>{DatePicker(isReadOnly)}</div>;
};

NexusDateTimePicker.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    isReadOnly: PropTypes.bool,
    isClearableOnly: PropTypes.bool,
    isViewModeDisabled: PropTypes.bool,
    isTimestamp: PropTypes.bool,
    isLabelHidden: PropTypes.bool,
    onConfirm: PropTypes.func,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    elementId: PropTypes.string,
    defaultValue: PropTypes.object,
    todayResetsToMidnight: PropTypes.bool,
};

NexusDateTimePicker.defaultProps = {
    label: '',
    value: '',
    isReadOnly: false,
    isClearableOnly: false,
    isViewModeDisabled: false,
    isTimestamp: false,
    isLabelHidden: false,
    onConfirm: () => null,
    elementId: '',
    defaultValue: {},
    todayResetsToMidnight: false,
};

export default NexusDateTimePicker;
