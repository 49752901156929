import {get} from 'lodash';
import {getFilteredCastList, getFilteredCrewList} from '../../utils/castCrewUtils';
import {CAST, PERSONS_PER_REQUEST} from './constants';

const handleResponse = (resTable, searchText, language, permissions) => {
    const lang = get(language, 'value') ? get(language, 'value') : language;
    if (resTable && resTable.length > 0) {
        return resTable.map(e => {
            let name;
            e.localization.forEach(local => {
                if (local.language === lang) {
                    if (lang === 'he') {
                        name = local.displayName.split('').reverse().join('');
                    } else {
                        name = local.displayName;
                    }
                }
            });

            return {
                id: e.id,
                name: name || e.displayName,
                byline: e.personType.toString().toUpperCase(),
                original: JSON.stringify(e),
            };
        });
    }
    // // check if the user has needed permissions from ENDPOINTS API
    // if (hasNeededPermission(CRUD_ACTIONS.CREATE, permissions)) {
    //     return {
    //         id: 'create',
    //         name: `+ Create "${searchText}"`,
    //         original: 'create',
    //     };
    // }
    // // if user has no permissions, and no data found
    // return {
    //     id: 'no-data',
    //     name: `No Data found for "${searchText}"`,
    //     original: 'no-data',
    // };
    return [];
};

export const loadOptions = (uiConfig, searchText, searchPerson, language, permissions) => {
    const {type} = uiConfig;
    if (type === CAST) {
        return searchPerson(searchText, PERSONS_PER_REQUEST, type, true).then(res => {
            const resTable = getFilteredCastList(res.data, true, true);
            return handleResponse(resTable, searchText, language, permissions);
        });
    }
    return searchPerson(searchText, PERSONS_PER_REQUEST, type).then(res => {
        const resTable = getFilteredCrewList(res.data, true);
        return handleResponse(resTable, searchText, language, permissions);
    });
};
