export const GET_TITLE = 'GET_TITLE';
export const GET_TITLE_SUCCESS = 'GET_TITLE_SUCCESS';
export const GET_TITLE_ERROR = 'GET_TITLE_ERROR';
export const GET_TITLE_LOADING = 'GET_TITLE_LOADING';
export const GET_EXTERNAL_IDS = 'GET_EXTERNAL_IDS';
export const GET_EXTERNAL_IDS_SUCCESS = 'GET_EXTERNAL_IDS_SUCCESS';
export const GET_EXTERNAL_IDS_ERROR = 'GET_EXTERNAL_IDS_ERROR';
export const GET_EXTERNAL_IDS_LOADING = 'GET_EXTERNAL_IDS_LOADING';
export const GET_TERRITORY_METADATA = 'GET_TERRITORY_METADATA';
export const GET_TERRITORY_METADATA_SUCCESS = 'GET_TERRITORY_METADATA_SUCCESS';
export const GET_TERRITORY_METADATA_ERROR = 'GET_TERRITORY_METADATA_ERROR';
export const GET_EDITORIAL_METADATA = 'GET_EDITORIAL_METADATA';
export const GET_EDITORIAL_METADATA_SUCCESS = 'GET_EDITORIAL_METADATA_SUCCESS';
export const GET_EDITORIAL_METADATA_ERROR = 'GET_EDITORIAL_METADATA_ERROR';
export const GET_EDITORIAL_METADATA_LOADING = 'GET_EDITORIAL_METADATA_LOADING';
export const UPLOAD_METADATA = 'UPLOAD_METADATA_FILES';
export const UPLOAD_METADATA_REQUEST = 'UPLOAD_METADATA_FILES_REQUEST';
export const UPLOAD_METADATA_ERROR = 'UPLOAD_METADATA_FILES_ERROR';
export const UPLOAD_METADATA_SUCCESS = 'UPLOAD_METADATA_FILES_SUCCESS';
export const UPDATE_TITLE = 'UPDATE_TITLE';
export const UPDATE_TITLE_SUCCESS = 'UPDATE_TITLE_SUCCESS';
export const UPDATE_TITLE_ERROR = 'UPDATE_TITLE_ERROR';
export const SYNC_TITLE = 'SYNC_TITLE';
export const PUBLISH_TITLE = 'PUBLISH_TITLE';
export const TITLE_IS_SYNCING_START = 'TITLE_IS_SYNCING_START';
export const TITLE_IS_SYNCING_END = 'TITLE_IS_SYNCING_END';
export const TITLE_IS_PUBLISHING_START = 'TITLE_IS_PUBLISHING_START';
export const TITLE_IS_PUBLISHING_END = 'TITLE_IS_PUBLISHING_END';
export const EDITING = 'EDIT_TITLE';
export const CLEAR_TITLE = 'CLEAR_TITLE';
export const CLEAR_SEASON_PERSONS = 'CLEAR_SEASON_PERSONS';
export const SET_TITLE_USER_DEFINED_GRID_STATE = 'SET_TITLE_USER_DEFINED_GRID_STATE';
export const SET_UPLOAD_LOG_TITLE_FILTER = 'SET_UPLOAD_LOG_TITLE_FILTER';
export const CLEAR_UPLOAD_LOG_TITLE_FILTER = 'SET_UPLOAD_LOG_TITLE_FILTER';
export const SET_TITLE_FILTER = 'SET_TITLE_FILTER';
export const CLEAR_TITLE_FILTER = 'CLEAR_TITLE_FILTER';
export const SET_CURRENT_USER_VIEW = 'SET_CURRENT_USER_VIEW';
export const SET_TITLE_CONTENT_TYPES = 'SET_TITLE_CONTENT_TYPES';
export const SET_EXTERNAL_ID_DROPDOWN = 'SET_EXTERNAL_ID_DROPDOWN';
export const SET_SELECTED_TITLES = 'SET_SELECTED_TITLES';
export const SET_TENANT_TAGS = 'SET_TENANT_TAGS';
export const SET_VERSION_TYPES = 'SET_VERSION_TYPES';
export const SET_PRODUCTION_STUDIOS = 'SET_PRODUCTION_STUDIOS';
export const SET_PERSON_TYPES = 'SET_PERSON_TYPES';
