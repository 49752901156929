import React from 'react';
import { ProgressSpinner } from '@portal/portal-components';

const Loading = () => (
    <div className="loading-wrapper h-100 w-100 d-flex align-items-center">
        <ProgressSpinner height={75} width={75} />
    </div>
);

Loading.propTypes = {};

Loading.defaultProps = {};

export default Loading;
