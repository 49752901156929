import React from 'react';
import './NotFound.scss';

const NotFound = () => {
    return (
        <div className="nexus-c-not-found">
            <h2>Not Found</h2>
        </div>
    );
};

export default NotFound;
