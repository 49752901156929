import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Dialog, Form, Label} from '@portal/portal-components';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {addToast} from '../../../toast/NexusToastNotificationActions';
import {generateValidHtmlCamelCaseId} from '../../../utils/utils';
import {CANCEL, DELETE, REMOVE_TITLE} from '../../nexus-tag/constants';
import {buildSection, checkFieldDependencies, getFieldConfig, renderError, renderNexusField} from '../utils';
import {DELETE_POPUP, FIELD_REQUIRED, VIEWS} from '../constants';
import './NexusArray.scss';

const NexusArray = ({
    name,
    view,
    data,
    fields,
    getValues,
    setFieldValue,
    confirmationContent,
    isRequired,
    isReadOnly,
    tooltip,
    dependencies,
    path,
    validationError,
    validation,
    selectValues,
    isUpdate,
    config,
    isEditable,
    generateMsvIds,
    sectionID,
}) => {
    const dispatch = useDispatch();
    // allData includes initialData and rows added/removed
    const [allData, setAllData] = useState(data);
    const [displayRemoveModal, setDisplayRemoveModal] = useState(false);
    const [displayEditModal, setDisplayEditModal] = useState(false);
    const [indexToRemove, setIndexToRemove] = useState(null);

    const form = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
    });

    useEffect(() => {
        setAllData(data);
    }, [data, isUpdate]);

    const onRemove = index => {
        const values = getValues();
        const editedData =
            path &&
            values[path].filter((obj, i) => {
                if (i === index && obj.selected) {
                    dispatch(
                        addToast({
                            detail: 'Selected territory cannot be removed',
                            severity: 'error',
                        })
                    );
                    return true;
                }

                return i !== index;
            });

        setAllData(editedData);
        setFieldValue(path, editedData);
        setDisplayRemoveModal(false);
    };

    const handleRemove = (index, e) => {
        e?.stopPropagation?.();
        e?.preventDefault?.();
        if (confirmationContent) {
            setIndexToRemove(index);
            setDisplayRemoveModal(true);
        } else {
            onRemove(index);
        }
    };

    const handleOnSubmit = values => {
        const data = [...getValues(path), values];

        setFieldValue(path, data);
        setDisplayEditModal(false);
        setAllData(data);
    };

    const openEditModal = e => {
        e.preventDefault();
        form.reset();
        setDisplayEditModal(true);
    };

    const required = !!(
        checkFieldDependencies('required', view, dependencies, {formData: getValues(), config, isEditable}) ||
        isRequired
    );

    const readOnly = !!(
        checkFieldDependencies('readOnly', view, dependencies, {formData: getValues(), config, isEditable}) ||
        isReadOnly
    );

    const renderAddButton = () => {
        return (
            <Button
                elementId={generateValidHtmlCamelCaseId(`btnOpenNexusArrayModal.${name}`)}
                label={`+ Add ${name}`}
                onClick={openEditModal}
                className="p-button-outlined p-button-secondary nexus-c-dynamic-form__add-button"
            />
        );
    };

    const renderObject = (data, index) => {
        return (
            <div key={index} className="nexus-c-array__object">
                {view !== VIEWS.VIEW && (
                    <Button
                        elementId="btnRemoveItem"
                        icon="po po-close"
                        className="p-button-text"
                        onClick={e => handleRemove(index, e)}
                    />
                )}
                <div className="row">{buildObject(fields, data || {}, index)}</div>
            </div>
        );
    };

    const buildObject = (fields = {}, data, index) => {
        return (
            <>
                {Object.keys(fields).map(key => {
                    return (
                        !getFieldConfig(fields[key], 'hidden', view) && (
                            <div key={`nexus-c-array__field ${key}`} className="col-6 array-field-wrapper">
                                {renderNexusField(`${path}[${index}].${key}`, view, getValues, generateMsvIds, {
                                    initialData: {},
                                    field: {
                                        ...fields[key],
                                        path: `${path}.${index}.${key}`,
                                        originalPath: fields[key].path,
                                    },
                                    sectionID,
                                    selectValues,
                                    setFieldValue,
                                    config,
                                    shouldStackLabel: true,
                                    path,
                                })}
                            </div>
                        )
                    );
                })}
            </>
        );
    };

    const buildButtons = (dirty, submitting, reset, isValid) => {
        return (
            <div className="nexus-c-array__modal-buttons">
                <Button
                    elementId="btnNexusArrayConfirmAction"
                    label="Confirm"
                    type="submit"
                    className="p-button-outlined nexus-c-array__submit-button"
                    disabled={!dirty || submitting || !isValid}
                />
                <Button
                    elementId="btnNexusArrayCancelAction"
                    label="Cancel"
                    className="p-button-outlined p-button-secondary nexus-c-array__cancel-button"
                    onClick={e => {
                        e.preventDefault();
                        reset();
                        setDisplayEditModal(false);
                    }}
                />
            </div>
        );
    };

    const modalContent = () => {
        return (
            <div className="nexus-c-array__modal">
                <Form onSubmit={values => handleOnSubmit(values)} form={form}>
                    <div className="nexus-c-array__modal-fields">
                        {buildSection(fields, path => form.getValues(path), VIEWS.CREATE, null, null, {
                            selectValues,
                            setFieldValue: (name, value) => form.setValue(name, value, {shouldDirty: true}),
                            sectionID,
                            isModal: true,
                        })}
                    </div>
                    {buildButtons(
                        form.formState.isDirty,
                        form.formState.isSubmitting,
                        form.reset,
                        form.formState.isValid
                    )}
                </Form>
            </div>
        );
    };

    const renderRemoveModalFooter = () => {
        return (
            <div className="row">
                <div className="col-12 text-end">
                    <Button
                        elementId={generateValidHtmlCamelCaseId(`nexusArrayCancel.${name}`)}
                        label={CANCEL}
                        onClick={() => setDisplayRemoveModal(false)}
                        className="p-button-outlined p-button-secondary"
                    />
                    <Button
                        elementId={generateValidHtmlCamelCaseId(`nexusArrayDelete.${name}`)}
                        label={DELETE}
                        onClick={() => {
                            onRemove(indexToRemove);
                            setDisplayRemoveModal(false);
                        }}
                        className="p-button-outlined"
                    />
                </div>
            </div>
        );
    };

    const hasError = () => {
        const val = getValues(path);
        return required && Array.isArray(val) ? !val.length : !val;
    };

    return (
        <>
            <div className={`nexus-c-array ${validationError ? 'nexus-c-array--error' : ''}`}>
                <div className="row align-items-center">
                    <div className="col-6 ps-4">
                        <Label label={name} shouldUpper={true} stacked={false} isRequired={required} />
                        {hasError() && renderError(FIELD_REQUIRED)}
                    </div>
                    <div className="col-6 pe-5">
                        {!readOnly && (
                            <div className="nexus-c-array__add">{view !== VIEWS.VIEW && renderAddButton()}</div>
                        )}
                    </div>
                </div>

                <div className="nexus-c-array__objects">
                    {allData && Array.isArray(allData) && allData.map((o, index) => renderObject(o, index))}
                </div>
            </div>
            <Dialog
                elementId="nexusModal_remove"
                header={REMOVE_TITLE}
                visible={displayRemoveModal}
                style={{width: '35vw'}}
                onHide={() => null}
                footer={renderRemoveModalFooter()}
                closeOnEscape={false}
                closable={false}
                onSubmit={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                {`${DELETE_POPUP} ${confirmationContent}`}
            </Dialog>
            <Dialog
                elementId={`nexusModal_edit${name}`}
                header={<div className="nexus-c-array__modal-title">{`Add ${name} Data`}</div>}
                visible={displayEditModal}
                style={{width: '55vw'}}
                onHide={() => null}
                closeOnEscape={false}
                closable={false}
                onSubmit={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                {modalContent()}
            </Dialog>
        </>
    );
};

NexusArray.propTypes = {
    name: PropTypes.string.isRequired,
    path: PropTypes.string,
    view: PropTypes.string,
    tooltip: PropTypes.string,
    data: PropTypes.array,
    fields: PropTypes.object,
    getValues: PropTypes.func,
    setFieldValue: PropTypes.func,
    confirmationContent: PropTypes.string,
    isRequired: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    validationError: PropTypes.string,
    validation: PropTypes.array,
    dependencies: PropTypes.array,
    selectValues: PropTypes.object,
    isUpdate: PropTypes.bool,
    config: PropTypes.array,
    isEditable: PropTypes.bool,
    generateMsvIds: PropTypes.func,
    sectionID: PropTypes.string,
};

NexusArray.defaultProps = {
    path: '',
    view: VIEWS.VIEW,
    tooltip: null,
    data: [],
    fields: {},
    getValues: undefined,
    setFieldValue: undefined,
    confirmationContent: null,
    isRequired: false,
    isReadOnly: false,
    validationError: null,
    validation: [],
    dependencies: [],
    selectValues: {},
    isUpdate: false,
    config: [],
    isEditable: false,
    generateMsvIds: undefined,
    sectionID: '',
};

export default NexusArray;
